import api from 'shared/api'
import { setSearchQueryParam, objectToQueryParams, formatOrderBy } from 'shared/helpers'

const offset = 20

export const fetchCustomers = (
  companyId,
  page = 0,
  query,
  queryType,
  orderBy,
  pageSize = 20,
  customerLabels = []
) => {
  const queryUrl = setSearchQueryParam(query, queryType)
  const orderUrl = formatOrderBy(orderBy)
  const customerLabelsFilter = customerLabels.length
    ? `&label__in=${customerLabels.map(l => l.value).join(',')}`
    : ''
  const url = `/api/v1/customers/?company=${companyId}&limit=${pageSize}&offset=${
    page ? page * pageSize : 0
  }${queryUrl}${orderUrl}${customerLabelsFilter}`
  return api.get(url)
}

export const fetchCustomer = (customerId, company) =>
  api.get(`/api/v1/customers/${customerId}/?company=${company}`)

export const patchCustomer = (customerID, companyID, data) =>
  api.patch(`/api/v1/customers/${customerID}/?company=${companyID}`, data)

export const createCustomer = (companyID, data) =>
  api.post(`/api/v1/customers/?company=${companyID}`, data)

export const deleteCustomer = (customerID, companyID) =>
  api.delete(`/api/v1/customers/${customerID}/?company=${companyID}`)

export const fetchCustomerOrders = (companyId, customerID, page = 0, orderBy) => {
  const orderUrl = formatOrderBy(orderBy)
  const url = `/api/v1/orders/?company=${companyId}&limit=20&offset=${page *
    offset}&customer_id=${customerID}${orderUrl}`
  return api.get(url)
}

export const fetchCustomerLicenses = (companyId, customerID, page = 0, orderBy) => {
  const orderUrl = formatOrderBy(orderBy)
  const url = `/api/v1/licenses/?company=${companyId}&limit=20&offset=${page *
    offset}${orderUrl}&customer_id=${customerID}&bundle_license__isnull=1`
  return api.get(url)
}

export const addLabelToCustomer = (data, companyID) =>
  api.post(`/api/v1/customerclabels/?company=${companyID}`, data)

export const editCustomerLabel = (labelID, companyID, data) =>
  api.patch(`/api/v1/clabels/${labelID}/?company=${companyID}`, data)

export const deleteLabelFromCustomer = (id, companyID) =>
  api.delete(`/api/v1/customerclabels/${id}/?company=${companyID}`)

export const addCompanyCustomerLabel = (companyId, data) =>
  api.post(`/api/v1/clabels/?company=${companyId}`, data)

export const deleteCustomerLabel = (labelID, companyID) =>
  api.delete(`/api/v1/clabels/${labelID}/?company=${companyID}`)

export const setLicenseUserPassword = (userId, companyID, data) => {
  const url = `/api/v1/license-users/${userId}/set_password/?company=${companyID}`
  return api.post(url, data)
}

export const updateUIOptions = (userPrefsID, companyID, data) => {
  const url = `/api/v1/users/${userPrefsID}/?company=${companyID}`
  return api.patch(url, data)
}

export const importCustomers = (companyID, data) =>
  api.post(`/api/v1/customers/import_from_csv/?company=${companyID}`, data)

export const getCustomerImportsResults = companyID =>
  api.get(`/api/v1/customers-import-results/?company=${companyID}&limit=100`)

export const fetchCustomerAccounts = (
  companyId,
  page = 0,
  query,
  queryType,
  orderBy,
  pageSize = 20
) => {
  const queryUrl = setSearchQueryParam(query, queryType)
  const orderUrl = orderBy
    ? `&order_by=${orderBy.map(orderType => `${orderType.desc ? '-' : ''}${orderType.id}`)}`
    : ''
  const url = `/api/v1/customer-accounts/?company=${companyId}&limit=${pageSize}&offset=${
    page ? page * pageSize : 0
  }${orderUrl}${queryUrl}`
  return api.get(url)
}

export const fetchCustomerAccount = (companyID, accountID) =>
  api.get(`/api/v1/customer-accounts/${accountID}/?company=${companyID}`)

export const fetchAccountCustomersList = (companyID, accountID) =>
  api.get(
    `/api/v1/customers/?customer_account=${accountID}&company=${companyID}&offset=0&limit=1000`
  )

export const fetchAccountOrdersList = (companyID, accountID) =>
  api.get(`/api/v1/orders/?customer__account=${accountID}&company=${companyID}&offset=0&limit=1000`)

export const fetchAccountLicensesList = (companyID, accountID) =>
  api.get(
    `/api/v1/licenses/?customer__account=${accountID}&company=${companyID}&offset=0&limit=1000`
  )

export const updateCustomerAccount = (companyID, accountID, data) =>
  api.patch(`/api/v1/customer-accounts/${accountID}/?company=${companyID}`, data)

export const createCustomerAccount = (companyID, data) =>
  api.post(`/api/v1/customer-accounts/?company=${companyID}`, data)

export const deleteCustomerAccount = (companyID, accountID) =>
  api.delete(`/api/v1/customer-accounts/${accountID}/?company=${companyID}`)

export const getCustomerAccProvider = (accountID, companyID) =>
  api.get(`/api/v1/customer-accounts/${accountID}/identity_provider/?company=${companyID}`)

export const deleteCustomerAccProvider = (accountID, companyID) =>
  api.delete(`/api/v1/customer-accounts/${accountID}/identity_provider/?company=${companyID}`)

export const addCustomerAccProvider = (accountID, companyID, data) =>
  api.post(`/api/v1/customer-accounts/${accountID}/identity_provider/?company=${companyID}`, data)

export const updateCustomerAccProvider = (companyID, accountID, data) =>
  api.patch(`/api/v1/customer-accounts/${accountID}/identity_provider/?company=${companyID}`, data)

export const changeRedirectUri = (accountID, companyID, data) =>
  api.patch(`/api/v1/customer-accounts/${accountID}/cognito_user_pool/?company=${companyID}`, data)

export const exportCustomers = data =>
  api.get(`/api/v1/customers/export/?${objectToQueryParams(data)}`)

// Oauth Customer
export const getOauthCustomerClient = (customerID, companyID) =>
  api.get(`/api/v1/customers/${customerID}/client/?company=${companyID}`)

export const createOauthCustomerClient = (customerID, companyID) =>
  api.post(`/api/v1/customers/${customerID}/client/?company=${companyID}`)

export const deleteOauthCustomerClient = (customerID, companyID) =>
  api.delete(`/api/v1/customers/${customerID}/client/?company=${companyID}`)

export const getOauthCustomerClientSecret = (customerID, companyID) =>
  api.get(`/api/v1/customers/${customerID}/client_secret/?company=${companyID}`)

export const createOauthCustomerClientSecret = (customerID, companyID) =>
  api.post(`/api/v1/customers/${customerID}/client_secret/?company=${companyID}`)

export const rotateOauthCustomerClientSecret = (customerID, companyID, data) =>
  api.post(`/api/v1/customers/${customerID}/client_secret_rotate/?company=${companyID}`, data)

// Oauth Customer Account
export const getOauthCustomerAccountClient = (accountID, companyID) =>
  api.get(`/api/v1/customer-accounts/${accountID}/client/?company=${companyID}`)

export const createOauthCustomerAccountClient = (accountID, companyID) =>
  api.post(`/api/v1/customer-accounts/${accountID}/client/?company=${companyID}`)

export const deleteOauthCustomerAccountClient = (accountID, companyID) =>
  api.delete(`/api/v1/customer-accounts/${accountID}/client/?company=${companyID}`)

export const getOauthCustomerAccountClientSecret = (accountID, companyID) =>
  api.get(`/api/v1/customer-accounts/${accountID}/client_secret/?company=${companyID}`)

export const createOauthCustomerAccountClientSecret = (accountID, companyID) =>
  api.post(`/api/v1/customer-accounts/${accountID}/client_secret/?company=${companyID}`)

export const rotateOauthCustomerAccountClientSecret = (accountID, companyID, data) =>
  api.post(
    `/api/v1/customer-accounts/${accountID}/client_secret_rotate/?company=${companyID}`,
    data
  )
