import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import {
  Checkbox,
  ConfirmationPopup,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  NumberInput,
  Selector
} from 'shared/components'
import { planTypes } from 'shared/constants'
import { sendErrorReport } from 'shared/helpers'
import { validateRequiredNumber, debouncedValidateRequiredNumber } from 'shared/validation'
import { getCompanySuccess } from 'src/company/actions'
import { changePlanType } from 'src/billing/actions'

const ChangePlanTypeForm = ({ companyDetails, closeCb, hasSubscription }) => {
  const dispatch = useDispatch()
  const companyPlanType = get(companyDetails, 'plan_type')

  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [disableTrial, setDisableTrial] = useState(false)
  const [trial, setTrial] = useState(15)
  const [trialError, setTrialError] = useState('')
  const [planType, setPlanType] = useState(companyPlanType)
  const [isDirtyFormAlertDisplayed, setIsDirtyFormAlertDisplayed] = useState(false)
  const [isActiveSubscriptionWarningDisplayed, setActiveSubscriptionWarningDisplayed] = useState(
    false
  )
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false)

  const validateTrialDays = async () => {
    setLoading(true)
    let errors
    try {
      errors = await validateRequiredNumber(trial)
      setTrialError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate trial days on change plant type form', { value: trial })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const handlePlanTypeChange = () => {
    setLoading(true)
    setCancelSubscriptionLoading(true)

    const trialDays = disableTrial ? 0 : trial
    const data = {
      plan_type: planType,
      trial_days: planType === 'standard' ? trialDays : undefined
    }

    changePlanType(get(companyDetails, 'id'), data)
      .then(res => {
        Notification('success', __('Plan type changed'))
        dispatch(getCompanySuccess(res.data))
        setActiveSubscriptionWarningDisplayed(false)
        closeCb()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot change plan type')
        setLoading(false)
        setCancelSubscriptionLoading(false)
      })
  }

  const isFormValid = async () => {
    const areTrialDaysValid = await validateTrialDays()
    return !!areTrialDaysValid
  }

  const handleSubmit = async () => {
    const isValid = await isFormValid()
    if (!isValid || loading) {
      return false
    }
    if (planType === companyPlanType) {
      closeCb()
      return false
    }

    if (hasSubscription) {
      setActiveSubscriptionWarningDisplayed(true)
    } else {
      handlePlanTypeChange()
    }
    return true
  }

  return (
    <Modal
      closeCb={() => setIsDirtyFormAlertDisplayed(true)}
      confirmCb={handleSubmit}
      title={__('Change plan type')}
      disabled={loading}
      size='sm'
    >
      <div className='ChangePlanTypeForm'>
        <Label inputId='plan-type-input' text={__('Plan type')} />
        <Selector
          getOptionLabel={option => `${option.label} - ${option.pricing}`}
          handleChange={val => {
            setTrial(15)
            setPlanType(val)
          }}
          maxWidth='300px'
          options={planTypes}
          value={planType}
        />
        {planType === 'standard' && companyPlanType !== 'standard' && (
          <div>
            <Label inputId='trial-input' text={__('Trial days')} />
            <NumberInput
              id='trial-input'
              min='1'
              max='10000'
              error={trialError}
              value={trial}
              disabled={disableTrial || loading}
              handleChange={val => {
                setDirty(true)
                setTrial(val)
                debouncedValidateRequiredNumber(val).then(err => setTrialError(err))
              }}
            />
            <Checkbox
              label={__('No trial period')}
              handleChange={val => setDisableTrial(val)}
              checked={disableTrial}
              inputId='disable-trial-input'
            />
          </div>
        )}
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setIsDirtyFormAlertDisplayed(false)}
            closeCb={closeCb}
          />
        )}
        {isActiveSubscriptionWarningDisplayed && (
          <ConfirmationPopup
            closeCb={() => {
              setActiveSubscriptionWarningDisplayed(false)
              closeCb()
            }}
            confirmCb={handlePlanTypeChange}
            title={__('This company has active subscription!')}
            confirmText={__('Confirm')}
            theme='error'
            warning
            disabled={cancelSubscriptionLoading}
          >
            {__(
              'Please, confirm that you really want to cancel this subscription and change company plan type.'
            )}
          </ConfirmationPopup>
        )}
      </div>
    </Modal>
  )
}

ChangePlanTypeForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyDetails: PropTypes.object.isRequired,
  hasSubscription: PropTypes.bool.isRequired
}

export default ChangePlanTypeForm
