import React from 'react'
import AirGap from './AirGap'
import LicenseAPI from './LicenseAPI'
import Oauth from './Oauth'
import ManagementAPI from './ManagementAPI'
import './styles.scss'

const ApiKeys = () => (
  <div className='ApiKeys Settings-keys'>
    <Oauth />
    <LicenseAPI />
    <ManagementAPI />
    <AirGap />
  </div>
)

export default ApiKeys
