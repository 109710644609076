import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import {
  DateInput,
  Checkbox,
  CheckboxSelector,
  SearchableCheckbox,
  CustomFormTab,
  DirtyFormAlert,
  InputErrorMessage,
  Label,
  LicenseTypeSelector,
  Modal,
  Notice,
  Notification,
  NumberInput,
  CustomFieldsEditableTable,
  RadioBtn,
  Selector,
  Switcher,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  TextInput,
  TextArea
} from 'shared/components'
import {
  sendErrorReport,
  getNumbersFromString,
  getCharsFromString,
  isFeatureEnabled,
  isFeatureAvailable,
  getDisabledMessage,
  setDefaultOverageType,
  formatPolicyFeatures,
  formatPolicyCFields,
  mapPolicyProductFeatures,
  mapPolicyCustomFields,
  parseMetadata
} from 'shared/helpers'
import {
  platformFeatures,
  errorMsg,
  licenseTypes,
  overageTypes,
  defaultDisabledFeatureMessage,
  validityTypes,
  trialTypes
} from 'shared/constants'
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
  validateRequiredNumber,
  debouncedValidateRequiredNumber,
  validateFloatingUsers,
  debouncedValidateFloatingUsers,
  validateJSON,
  debouncedValidateJSON,
  validateDate,
  debouncedValidateDate
} from 'shared/validation'
import { createLicensePolicy, patchLicensePolicy, getProducts } from 'src/product/actions'
import ProductFeaturesEditor from 'src/license/ProductFeaturesEditor'
import './styles.scss'

const LicensePolicyForm = ({ companyID, closeCb, policies, policy, product, refetchPolicies }) => {
  const dispatch = useDispatch()
  const planType = useSelector(state => get(state, 'company.details.plan_type'))
  // todo_metadata replace this check with company feature
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const isEnterprisePlan = get(companyDetails, 'plan_type') === 'enterprise'

  const productID = get(product, 'id')
  const periodOptions = [
    { label: __('Years'), value: 'y' },
    { label: __('Months'), value: 'm' },
    { label: __('Days'), value: 'd' }
  ]
  const consumptionPeriodOptions = [
    { label: __('Daily'), value: 'daily' },
    { label: __('Weekly'), value: 'weekly' },
    { label: __('Monthly'), value: 'monthly' },
    { label: __('Annually'), value: 'annually' }
  ]
  const notAvailablePreventVmMessage = (
    <>
      {__('Virtual machine detection and management is not available in your plan.')}
      &nbsp;
      {__('Contact sales to support VM prevention.')}
    </>
  )
  const notAvailableFloatingMessage = (
    <>
      {__('Floating licenses are unavailable in your current plan.')}
      &nbsp;
      {__('Upgrade to use floating licenses.')}
    </>
  )
  const notAvailableMaintenanceMessage = (
    <>
      {__('Maintenance plan is unavailable in your current plan.')}
      &nbsp;
      {__('Upgrade to use maintenance plans.')}
    </>
  )

  const notAvailableMaxTransfersMessage = (
    <>
      {__('Device transfer limits are not available in your plan.')}
      &nbsp;
      {__('Contact sales to support device transfer limit.')}
    </>
  )
  const notAvailableFeaturesMessage = (
    <>
      {__('Licensing features in your applications is not available in your plan.')}
      &nbsp;
      {__('Upgrade to use features licensing.')}
    </>
  )

  const notAvailableHwKeyMessage = (
    <>
      {__('Hardware key licensing is not available in your current plan.')}
      &nbsp;
      {__('Contact sales to support hardware key licensing.')}
    </>
  )

  const notAvailableCustomFieldsMessage =
    planType === 'free'
      ? __('Custom Fields are not available in your plan.')
      : __('Your plan allows for one custom field.')

  const productFloatingTimeout = get(product, 'floating_timeout')
  const isUserBasedProduct = get(product, 'authorization_method') === 'user'

  const getInitialSelectedDurationType = () => {
    const hasValidityPeriod = get(policy, 'validity_period')
    if (hasValidityPeriod) {
      return validityTypes.period
    }
    return validityTypes.duration
  }

  const getInitialSelectedTrialType = () => {
    const hasValidityPeriod = get(policy, 'validity_period')
    if (hasValidityPeriod) {
      return trialTypes.endDate
    }
    return trialTypes.days
  }

  const [isLoading, setLoading] = useState(false)
  const [isDirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)

  // policy identity
  const [name, setName] = useState(get(policy, 'name') || '')
  const [nameError, setNameError] = useState('')
  const [code, setCode] = useState(get(policy, 'code') || '')
  const [codeError, setCodeError] = useState('')
  // license type
  const [selectedLicenseType, setSelectedLicenseType] = useState(
    get(policy, 'default_license_type') || licenseTypes.perpetual
  )
  // const [licenseDurationValue, setLicenseDurationValue] = useState(getNumbersFromString(get(policy, 'valid_duration') || '1y'));
  // const [licenseDurationPeriod, setLicenseDurationPeriod] = useState(getCharsFromString(get(policy, 'valid_duration') || '1y'));
  // const [licenseDurationError, setLicenseDurationError] = useState('');

  const [selectedDurationType, setSelectedDurationType] = useState(getInitialSelectedDurationType())
  const [licenseDurationValue, setLicenseDurationValue] = useState(
    getNumbersFromString(get(policy, 'valid_duration') || '1y')
  )
  const [licenseDurationPeriod, setLicenseDurationPeriod] = useState(
    getCharsFromString(get(policy, 'valid_duration') || '1y')
  )
  const [licenseDurationError, setLicenseDurationError] = useState('')
  const [validUntil, setValidUntil] = useState(get(policy, 'validity_period') || '')
  const [validUntilError, setValidUntilError] = useState('')

  // grace period
  const [gracePeriod, setGracePeriod] = useState(get(policy, 'grace_period') || 1)
  const [gracePeriodError, setGracePeriodError] = useState('')
  const [allowGracePeriod, setAllowGracePeriod] = useState(
    get(policy, 'allow_grace_period') || false
  )
  // trials
  const [isTrial, setTrial] = useState(!!get(policy, 'allow_trial'))

  const [selectedTrialType, setSelectedTrialType] = useState(getInitialSelectedTrialType())
  const [trialDays, setTrialDays] = useState(get(policy, 'trial_days') || 1)
  const [trialDaysError, setTrialDaysError] = useState('')
  const [trialEndDate, setTrialEndDate] = useState(get(policy, 'validity_period') || '')
  const [trialEndDateError, setTrialEndDateError] = useState('')

  // const [trialDays, setTrialDays] = useState(get(policy, 'trial_days') || 1);
  // const [trialDaysError, setTrialDaysError] = useState('');
  // consumptions
  const [maxConsumptions, setMaxConsumptions] = useState(get(policy, 'max_consumptions') || 1)
  const [maxConsumptionsError, setMaxConsumptionsError] = useState('')
  const [allowUnlimitedConsumptions, setAllowUnlimitedConsumptions] = useState(
    get(policy, 'allow_unlimited_consumptions') || false
  )
  const [allowNegativeConsumptions, setAllowNegativeConsumptions] = useState(
    get(policy, 'allow_negative_consumptions') || false
  )
  const [allowOverages, setAllowOverages] = useState(get(policy, 'allow_overages') || false)
  const [maxOverage, setMaxOverage] = useState(get(policy, 'max_overages') || 1)
  const [maxOverageError, setMaxOverageError] = useState('')
  const [selectedOverageType, setSelectedOverageType] = useState(setDefaultOverageType(policy))
  const [resetConsumption, setResetConsumption] = useState(
    get(policy, 'reset_consumption') || false
  )
  const [consumptionPeriod, setConsumptionPeriod] = useState(
    get(policy, 'consumption_period') || get(consumptionPeriodOptions, '[0].value')
  )
  // floating
  const [isFloating, setIsFloating] = useState(get(policy, 'is_floating') || false)
  const [isFloatingCloud, setIsFloatingCloud] = useState(get(policy, 'is_floating_cloud') || false)
  const [floatingUsers, setFloatingUsers] = useState(get(policy, 'floating_users') || 1)
  const [floatingUsersError, setFloatingUsersError] = useState('')
  const [floatingTimeout, setFloatingTimeout] = useState(
    get(policy, 'floating_timeout') || productFloatingTimeout || 120
  )
  const [floatingTimeoutError, setFloatingTimeoutError] = useState('')
  // maintenance
  const [hasMaintenance, setHasMaintenance] = useState(
    get(policy, 'enable_maintenance_period') || false
  )
  const [hasMaintenanceValue, setHasMaintenanceValue] = useState(
    getNumbersFromString(get(policy, 'maintenance_duration') || '1y')
  )
  const [hasMaintenancePeriod, setHasMaintenancePeriod] = useState(
    getCharsFromString(get(policy, 'maintenance_duration') || '1y')
  )
  const [hasMaintenanceError, setHasMaintenanceError] = useState('')
  // options
  const [maxActivations, setMaxActivations] = useState(get(policy, 'max_activations') || 1)
  const [maxActivationsError, setMaxActivationsError] = useState('')
  const [allowUnlimitedActivations, setAllowUnlimitedActivations] = useState(
    get(policy, 'allow_unlimited_activations') || false
  )
  const [maxLicenseUsers, setMaxLicenseUsers] = useState(get(policy, 'max_license_users'))
  const [maxLicenseUsersError, setMaxLicenseUsersError] = useState('')
  const [unlimitedMaxLicenseUsers, setUnlimitedMaxLicenseUsers] = useState(
    get(policy, 'max_license_users') === 0 && get(policy, 'is_floating_cloud')
  )
  const [preventVm, setPreventVm] = useState(get(policy, 'prevent_vm') || false)
  const [preventMaxTransfers, setPreventMaxTransfers] = useState(
    get(policy, 'max_transfers') === -1
  )
  const [hasMaxTransfers, setHasMaxTransfers] = useState(
    get(policy, 'max_transfers') ? get(policy, 'max_transfers') > 0 : false
  )
  const [maxTransfers, setMaxTransfers] = useState(get(policy, 'max_transfers'))
  const [maxTransfersError, setMaxTransfersError] = useState('')

  // product features
  const [selectedProductFeatures, setSelectedProductFeatures] = useState(
    formatPolicyFeatures(get(policy, 'license_product_feature_templates') || [])
  )
  const [productFeaturesErrors, setProductFeaturesError] = useState(false)
  // custom fields
  const [selectedCustomFields, setSelectedCustomFields] = useState(
    formatPolicyCFields(get(policy, 'license_custom_field_templates') || [])
  )
  const [customFieldsError, setCustomFieldsError] = useState(false)

  // air-gapped
  const [isAirGapped, setAirGapped] = useState(get(policy, 'is_air_gapped'))

  // hardware_key
  const [isHwKeyAuth, setHwKeyAuth] = useState(get(policy, 'is_hardware_key_auth'))

  // license borrowing
  const [canBorrow, setCanBorrow] = useState(get(policy, 'can_borrow') || false)
  const [maxBorrowTime, setMaxBorrowTime] = useState(get(policy, 'max_borrow_time') || 0)
  const [maxBorrowTimeError, setMaxBorrowTimeError] = useState('')

  // metadata
  const [metadata, setMetadata] = useState(parseMetadata(get(policy, 'metadata')))
  const [metadataError, setMetadataError] = useState('')

  // ______________________ CREATE DATA _______________________________
  const getValidityPeriod = () => {
    const isPerpetual = selectedLicenseType === licenseTypes.perpetual
    const isTrialEndDate = isTrial && selectedTrialType === trialTypes.endDate
    const isTimeLimitedEndDate =
      selectedLicenseType === licenseTypes.time_limited &&
      selectedDurationType === validityTypes.period
    const isSubscriptionEndDate =
      selectedLicenseType === licenseTypes.subscription &&
      selectedDurationType === validityTypes.period

    if (!isTrial && isPerpetual) {
      return null
    }
    if (isTrialEndDate) {
      return trialEndDate
    }
    if (isTimeLimitedEndDate && !isTrial) {
      return validUntil
    }
    if (isSubscriptionEndDate) {
      return validUntil
    }
    return undefined
  }

  const getValidDuration = () => {
    const isPerpetual = selectedLicenseType === licenseTypes.perpetual
    const isConsumption = selectedLicenseType === licenseTypes.consumption
    // const isSubscription = selectedLicenseType === licenseTypes.subscription;
    const isDurationValid = licenseDurationValue && licenseDurationPeriod

    if (isPerpetual || isConsumption) {
      return null
    }
    if (isDurationValid) {
      return `${licenseDurationValue}${licenseDurationPeriod}`
    }
    return undefined
  }

  const getTrialDays = () => {
    if (!isTrial) {
      return undefined
    }

    if (trialEndDate && selectedTrialType === trialTypes.endDate) {
      return undefined
    }

    if (selectedTrialType === trialTypes.days) {
      return Number(trialDays)
    }
    return undefined
  }

  const getMaintenanceDuration = () => {
    if (!hasMaintenance) {
      return undefined
    }
    if (hasMaintenanceValue && hasMaintenancePeriod) {
      return `${hasMaintenanceValue}${hasMaintenancePeriod}`
    }
    return undefined
  }

  const getMaxOverage = () => {
    if (!allowOverages) {
      return undefined
    }
    if (selectedOverageType === overageTypes.unlimited) {
      return 0
    }
    if (selectedOverageType === overageTypes.custom) {
      return Number(maxOverage)
    }
    return undefined
  }

  const getFloatingUsers = () => {
    if (!isFloating && !isFloatingCloud) {
      return undefined
    }
    if (floatingUsers) {
      return Number(floatingUsers)
    }
    return undefined
  }

  const getFloatingTimeout = () => {
    if (!isFloating && !isFloatingCloud) {
      return undefined
    }
    if (floatingTimeout) {
      return Number(floatingTimeout)
    }
    return undefined
  }

  const getFeatures = () => {
    const data = selectedProductFeatures.map(pf => ({
      id: pf.value,
      name: pf.label,
      code: pf.code,
      feature_type: pf.feature_type,
      allow_overages: pf.allow_overages,
      max_overages: pf.max_overages,
      reset_consumption: pf.reset_consumption,
      consumption_period: pf.consumption_period,
      max_consumption: pf.max_consumption,
      allow_unlimited_consumptions: pf.allow_unlimited_consumptions,
      allow_negative_consumptions: pf.allow_negative_consumptions,
      metadata: pf.metadata || {},
      is_floating: pf.is_floating,
      is_floating_cloud: pf.is_floating_cloud,
      floating_users: pf.floating_users,
      floating_timeout: pf.floating_timeout
    }))
    return data
  }

  const getCFields = () => {
    const data = selectedCustomFields.map(pf => ({
      id: pf.value,
      default_value: pf.cf_value
    }))
    return data
  }

  const getMaxTransfers = () => {
    if (preventMaxTransfers) {
      return -1
    }
    if (hasMaxTransfers) {
      return Number(maxTransfers)
    }
    return 0
  }

  const createPolicyData = () => {
    const maxOverages = getMaxOverage()
    const floatingUsersNumber = getFloatingUsers()
    const floatingTimeoutValue = getFloatingTimeout()
    const maintenanceDuration = getMaintenanceDuration()
    const features = getFeatures()
    const cfields = getCFields()
    const maxTransfersValue = getMaxTransfers()
    const trialDaysNumber = getTrialDays()
    const isSubscription = selectedDurationType === validityTypes.subscription

    // Handle time-limited license duration and validity period
    let validDuration = null
    let validityPeriod = null

    if (selectedLicenseType === licenseTypes.time_limited) {
      if (selectedDurationType === validityTypes.duration) {
        // Valid from activation: set duration, clear validity period
        validDuration =
          licenseDurationValue && licenseDurationPeriod
            ? `${licenseDurationValue}${licenseDurationPeriod}`
            : null
        validityPeriod = null
      } else if (selectedDurationType === validityTypes.period) {
        // End date: set validity period, clear duration
        validDuration = null
        validityPeriod = validUntil
      }
    } else {
      // Handle other license types
      validDuration = getValidDuration()
      validityPeriod = getValidityPeriod()
    }

    const data = {
      name,
      code,
      product: productID,
      default_license_type: selectedLicenseType,
      // duration
      valid_duration: validDuration,
      validity_period: isSubscription ? undefined : validityPeriod, // integration should handle this
      // grace period
      allow_grace_period: allowGracePeriod,
      grace_period: allowGracePeriod ? gracePeriod : undefined,
      // trial
      allow_trial: isTrial,
      trial_days: trialDaysNumber,
      // consumption
      max_consumptions: Number(maxConsumptions),
      allow_unlimited_consumptions: allowUnlimitedConsumptions,
      allow_negative_consumptions: allowNegativeConsumptions,
      allow_overages: allowOverages,
      max_overages: maxOverages,
      reset_consumption: resetConsumption,
      consumption_period: resetConsumption ? consumptionPeriod : undefined,
      // options
      max_activations: Number(maxActivations),
      allow_unlimited_activations: allowUnlimitedActivations,
      max_license_users: isUserBasedProduct ? Number(maxLicenseUsers) : undefined,
      prevent_vm: preventVm,
      max_transfers: maxTransfersValue,
      is_floating: isFloating,
      is_floating_cloud: isFloatingCloud,
      floating_users: floatingUsersNumber,
      floating_timeout: floatingTimeoutValue || productFloatingTimeout,
      // maintenance
      enable_maintenance_period: hasMaintenance,
      maintenance_duration: maintenanceDuration,
      // product features
      license_product_feature_templates: features,
      license_custom_field_templates: cfields,
      // air-gapped
      is_air_gapped: isAirGapped,
      // hardware_key
      is_hardware_key_auth: isHwKeyAuth,
      // borrowing
      can_borrow: canBorrow,
      max_borrow_time: maxBorrowTime,
      // metadata
      metadata: metadata ? JSON.parse(metadata) : {}
    }
    return data
  }

  // ______________________ VALIDATIONS _______________________________
  const validateValue = async (val, cb, includeZero) => {
    setLoading(true)
    let errors
    try {
      errors = await validateRequiredValue(val, includeZero)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit license form value', {
        value: val
      })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const validateNumericalValue = async (val, cb, includeZero) => {
    setLoading(true)
    let errors
    try {
      errors = await validateRequiredNumber(val, includeZero)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit license form value', {
        value: val
      })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const validateUniqueCode = async () => {
    if (policy) {
      return true
    }
    if (!code) {
      const error = await validateRequiredValue(code)
      setCodeError(error)
      return false
    }

    const codeToTest = code.toLowerCase()
    const doesExist = policies.find(p => p.code === codeToTest)

    if (doesExist) {
      setCodeError(`${__('This code is already used for policy')}: ${get(doesExist, 'name')}`)
      return false
    }

    return true
  }

  const validateMaxActivations = async () => {
    setLoading(true)
    let errors

    try {
      errors = await validateRequiredNumber(maxActivations)
      setLoading(false)
      setMaxActivationsError(errors)

      if (
        !errors &&
        isUserBasedProduct &&
        !allowUnlimitedActivations &&
        Number(maxActivations) < Number(maxLicenseUsers)
      ) {
        setMaxActivationsError(errorMsg.maxActivations)
        errors = true
      }
    } catch (err) {
      setLoading(false)
      sendErrorReport(err, 'Cannot validate max activations', {
        value: maxActivations
      })
    }

    if (errors) {
      return false
    }
    return true
  }

  const validateLicenseDuration = async () => {
    if (isTrial) {
      return true
    }
    if (selectedLicenseType === licenseTypes.subscription) {
      return true
    }
    if (
      selectedLicenseType === licenseTypes.time_limited &&
      selectedDurationType === validityTypes.period
    ) {
      return true
    }
    const validationErr = await validateRequiredNumber(licenseDurationValue)
    setLicenseDurationError(validationErr)

    if (!licenseDurationPeriod) {
      setLicenseDurationError(__(errorMsg.required))
      return false
    }
    if (validationErr) {
      return false
    }
    return true
  }

  const validateExpirationDate = async (isOptional = false) => {
    if (isTrial) {
      return true
    }
    if (selectedLicenseType === licenseTypes.subscription) {
      return true
    }
    if (
      selectedLicenseType === licenseTypes.time_limited &&
      selectedDurationType === validityTypes.duration
    ) {
      return true
    }
    if (!validUntil && isOptional) {
      setValidUntilError('')
      return true
    }
    const validationErr = await validateDate(validUntil)
    setValidUntilError(validationErr)
    if (validationErr) {
      return false
    }
    return true
  }

  const validateGracePeriod = async () => {
    if (!allowGracePeriod) {
      return true
    }
    const isGracePeriodValid = await validateNumericalValue(gracePeriod, setGracePeriodError)
    return isGracePeriodValid
  }

  const validateConsumption = async () => {
    const isMaxConsumptionsValid = await validateNumericalValue(
      maxConsumptions,
      setMaxConsumptionsError
    )
    const isMaxOverageValid = await validateNumericalValue(maxOverage, setMaxOverageError, true)
    return isMaxConsumptionsValid && isMaxOverageValid
  }

  const validateLicenseTypeData = async () => {
    if (selectedLicenseType === licenseTypes.subscription) {
      const isDurationValid = await validateLicenseDuration()
      const isDateValid = await validateExpirationDate()
      return isDurationValid && isDateValid
    }
    if (selectedLicenseType === licenseTypes.time_limited) {
      const isDurationValid = await validateLicenseDuration()
      const isDateValid = await validateExpirationDate()
      return isDurationValid && isDateValid
    }
    if (selectedLicenseType === licenseTypes.consumption) {
      const isValid = await validateConsumption()
      return isValid
    }
    return true
  }

  const validateMaintenanceDuration = async () => {
    const validationErr = await validateRequiredNumber(hasMaintenanceValue)
    setHasMaintenanceError(validationErr)
    if (validationErr) {
      return false
    }
    return true
  }

  const validateMaintenance = async () => {
    if (!hasMaintenance) {
      return true
    }
    const isDurationValid = await validateMaintenanceDuration()
    return isDurationValid
  }

  const validateTrial = async () => {
    if (!isTrial) {
      return true
    }
    if (selectedLicenseType === licenseTypes.subscription) {
      return true
    }
    if (selectedTrialType === trialTypes.days) {
      const isTrialDaysValid = await validateNumericalValue(trialDays, setTrialDaysError)
      return !!isTrialDaysValid
    }

    if (selectedTrialType === trialTypes.endDate) {
      const validationErr = await validateDate(trialEndDate)
      setTrialEndDateError(validationErr)
      if (validationErr) {
        return false
      }
      return true
    }
    return false
  }

  const validateFloating = async () => {
    if (!isFloating && !isFloatingCloud) {
      return true
    }
    const validationErr = await validateFloatingUsers(
      floatingUsers,
      maxActivations,
      allowUnlimitedActivations,
      isFloatingCloud
    )
    setFloatingUsersError(validationErr)
    if (validationErr) {
      return false
    }
    return true
  }

  const validateFeaturesConsumption = async values => {
    if (!values.length) {
      setProductFeaturesError(false)
      return true
    }

    const hasError = values.some(f => f.max_consumption_error || f.max_overages_error)
    if (hasError || productFeaturesErrors) {
      setProductFeaturesError(true)
      return false
    }
    setProductFeaturesError(false)
    return true
  }

  const validateCustomFields = async values => {
    if (!values.length) {
      setCustomFieldsError(false)
      return true
    }

    const hasError = values.some(f => f.cf_value_error)
    if (hasError || customFieldsError) {
      setCustomFieldsError(true)
      return false
    }
    setCustomFieldsError(false)
    return true
  }

  const validateBorrowing = async () => {
    if (!canBorrow) {
      return true
    }
    const isMaxBorrowTimeValid = await validateNumericalValue(maxBorrowTime, setMaxBorrowTimeError)
    return isMaxBorrowTimeValid
  }

  const validateMetadata = async val => {
    setLoading(true)
    let errors
    try {
      errors = await validateJSON(metadata)
      setMetadataError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit feature form value', {
        value: val
      })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isNameValid = await validateValue(name, setNameError)
    const isCodeValid = await validateUniqueCode()
    const isLicenseTypeDataValid = await validateLicenseTypeData()
    const isMaxActivationsValid = await validateMaxActivations()
    const isMaintenanceValid = await validateMaintenance()
    const isGracePeriodValid = await validateGracePeriod()
    const isTrialValid = await validateTrial()
    const isFloatingValid = await validateFloating()
    const isFloatingTimeoutValid =
      isFloating || isFloatingCloud
        ? await validateNumericalValue(floatingTimeout, setFloatingTimeoutError)
        : true
    const isMaxLicenseUsersValid = isUserBasedProduct
      ? await validateNumericalValue(maxLicenseUsers, setMaxLicenseUsersError, isFloatingCloud)
      : true
    const isMaxTransfersValid = hasMaxTransfers
      ? await validateValue(maxTransfers, setMaxTransfersError)
      : true
    const areProductFeaturesValid = await validateFeaturesConsumption(selectedProductFeatures)
    const areCFieldsValid = await validateCustomFields(selectedCustomFields)
    const isBorrowingValid = await validateBorrowing()
    const isMetadataValid = await validateMetadata()

    return (
      isNameValid &&
      isCodeValid &&
      isLicenseTypeDataValid &&
      isMaxActivationsValid &&
      isMaintenanceValid &&
      isTrialValid &&
      isFloatingValid &&
      isGracePeriodValid &&
      isFloatingTimeoutValid &&
      isMaxLicenseUsersValid &&
      isMaxTransfersValid &&
      areProductFeaturesValid &&
      areCFieldsValid &&
      isBorrowingValid &&
      isMetadataValid
    )
  }

  const handleCanBorrowChange = val => {
    setDirty(true)
    setCanBorrow(val)
    setMaxBorrowTime(get(policy, 'max_borrow_time') || 0)
    setMaxBorrowTimeError('')
  }

  const handleMaxBorrowTimeChange = async val => {
    setDirty(true)
    setMaxBorrowTime(val)
    const validationErr = await debouncedValidateRequiredNumber(val)
    setMaxBorrowTimeError(validationErr)
  }

  const handleNameChange = async val => {
    setDirty(true)
    setName(val)
    const validationErr = await debouncedValidateRequiredValue(val)
    setNameError(validationErr)
  }

  const handleCodeChange = async val => {
    setDirty(true)
    setCode(val)
    const validationErr = await debouncedValidateRequiredValue(val)
    setCodeError(validationErr)
  }

  const handleLicenseTypeChange = val => {
    setDirty(true)
    setSelectedLicenseType(val)
    setLicenseDurationValue(getNumbersFromString(get(policy, 'valid_duration') || '1y'))
    setLicenseDurationPeriod(getCharsFromString(get(policy, 'valid_duration') || '1y'))
    setLicenseDurationError('')
    setMaxConsumptions(get(policy, 'max_consumptions') || 1)
    setMaxConsumptionsError('')
    setAllowOverages(get(policy, 'allow_overages') || false)
    setSelectedOverageType(setDefaultOverageType(policy))
    setMaxOverage(get(policy, 'max_overages') || 1)
    setMaxOverageError('')
    setResetConsumption(get(policy, 'reset_consumption') || false)
    setConsumptionPeriod(get(policy, 'consumption_period'))
  }

  const handleAllowTrialChange = val => {
    setDirty(true)
    setTrial(val)
    setTrialDays(get(policy, 'trial_days'))
    setTrialDaysError('')
  }

  const handleValidUntilChange = async (val, isOptional = false) => {
    setDirty(true)
    if (!val && isOptional) {
      setValidUntil(val)
      setValidUntilError('')
      return true
    }

    setValidUntil(val)
    const validationErr = await validateDate(val)
    setValidUntilError(validationErr)
    return true
  }

  const handleSelectedTrialTypeChange = val => {
    setDirty(true)
    setSelectedTrialType(val)
    setTrialDays(get(policy, 'trial_days') || 0)
    setTrialDaysError('')
    setTrialEndDate(get(policy, 'validity_period') || '')
    setTrialEndDateError('')
  }

  const handleLicenseDurationValueChange = async val => {
    setDirty(true)
    setLicenseDurationValue(val)
    const validationErr = await debouncedValidateRequiredNumber(val)
    setLicenseDurationError(validationErr)
  }

  const handleLicenseDurationPeriodChange = val => {
    setDirty(true)
    setLicenseDurationPeriod(val)
  }

  const handleAllowGracePeriod = val => {
    setDirty(true)
    setAllowGracePeriod(val)
    setGracePeriod(get(policy, 'grace_period') || 1)
    setGracePeriodError('')
  }

  const handleGracePeriodChange = val => {
    setDirty(true)
    setGracePeriod(val)
    debouncedValidateRequiredNumber(val, true).then(err => setGracePeriodError(err))
  }

  const handleMaxConsumptionsChange = val => {
    setDirty(true)
    setMaxConsumptions(val)
    debouncedValidateRequiredNumber(val).then(err => setMaxConsumptionsError(err))
  }

  const handleAllowUnlimitedConsumptions = val => {
    setDirty(true)
    setAllowUnlimitedConsumptions(val)
    setMaxConsumptions(get(policy, 'max_consumptions') || 1)
    setMaxConsumptionsError('')
  }

  const handleAllowOveragesChange = val => {
    setDirty(true)
    setAllowOverages(val)
    setMaxOverage(get(policy, 'max_overages') || 1)
    setMaxOverageError('')
  }

  const handleUnlimitedOveragesSelect = val => {
    setDirty(true)
    setSelectedOverageType(val)
    setMaxOverage(0)
    setMaxOverageError('')
  }

  const handleMaxOveragesSelect = val => {
    setDirty(true)
    setSelectedOverageType(val)
    setMaxOverage(get(policy, 'max_overages') || 1)
    setMaxOverageError('')
  }

  const handleMaxOveragesChange = val => {
    setDirty(true)
    setMaxOverage(val)
    debouncedValidateRequiredNumber(val, true).then(err => setMaxOverageError(err))
  }

  const handleResetConsumptionsChange = val => {
    setDirty(true)
    setResetConsumption(val)
    setConsumptionPeriod(
      get(policy, 'consumption_period') || get(consumptionPeriodOptions, '[0].value')
    )
  }

  const handleConsumptionsPeriodChange = val => {
    setDirty(true)
    setConsumptionPeriod(val)
  }

  const handleMaxActivationsChange = async val => {
    setDirty(true)
    setMaxActivations(val)
    const validationErr = await debouncedValidateRequiredNumber(val)
    setMaxActivationsError(validationErr)
    if (Number(val) >= Number(floatingUsers)) {
      setFloatingUsersError('')
    }
    if (
      !validationErr &&
      isUserBasedProduct &&
      Number(val) < Number(maxLicenseUsers) &&
      !allowUnlimitedActivations
    ) {
      setMaxActivationsError(errorMsg.maxActivations)
    }
  }

  const handleAllowUnlimitedActivations = val => {
    setDirty(true)
    setAllowUnlimitedActivations(val)
    setMaxActivations(get(policy, 'max_activations') || 1)
    setMaxActivationsError('')
    if (floatingUsers && !!val) {
      setFloatingUsersError('')
    }
  }

  const handlePreventDeviceTransfers = val => {
    setDirty(true)
    setPreventMaxTransfers(val)
    setMaxTransfers(-1)
    if (val) {
      setHasMaxTransfers(false)
    }
  }

  const handleMaxTransfersSelect = val => {
    setDirty(true)
    setHasMaxTransfers(val)
    setMaxTransfers(get(policy, 'max_transfers') || 0)
    setMaxTransfersError('')
    if (val) {
      setPreventMaxTransfers(false)
    }
  }

  const handleMaxTransfersChange = val => {
    setDirty(true)
    setMaxTransfers(val)
    validateNumericalValue(val, setMaxTransfersError)
  }

  const handleMaxUsersChange = val => {
    setDirty(true)
    setMaxLicenseUsers(val)
    debouncedValidateRequiredNumber(val, isFloatingCloud).then(err => setMaxLicenseUsersError(err))
  }

  const handleUnlimitedMaxUsersSelect = val => {
    setDirty(true)
    setUnlimitedMaxLicenseUsers(val)
    if (val) {
      setMaxLicenseUsers(0)
      setMaxLicenseUsersError('')
    }
  }

  const handlePreventVmChange = val => {
    setDirty(true)
    setPreventVm(val)
  }

  const handleFloatingSelect = val => {
    setDirty(true)
    setIsFloating(val)
    setIsFloatingCloud(false)
    setFloatingUsers(get(policy, 'floating_users') || 1)
    setFloatingUsersError('')
  }

  const handleFloatingCloudSelect = val => {
    setDirty(true)
    setIsFloatingCloud(val)
    setIsFloating(false)
    setFloatingUsers(get(policy, 'floating_users') || 1)
    setFloatingUsersError('')
    if (!val) {
      setUnlimitedMaxLicenseUsers(false)
    }
    if (val && maxLicenseUsers === 0) {
      setMaxLicenseUsersError('')
      setUnlimitedMaxLicenseUsers(true)
    }
  }

  const handleFloatingUsersChange = val => {
    setDirty(true)
    setFloatingUsers(val)
    debouncedValidateFloatingUsers(
      val,
      maxActivations,
      allowUnlimitedActivations,
      isFloatingCloud
    ).then(err => setFloatingUsersError(err))
  }

  const handleFloatingTimeoutChange = val => {
    setDirty(true)
    setFloatingTimeout(val)
    debouncedValidateRequiredNumber(val).then(err => setFloatingTimeoutError(err))
  }

  const handleEnableMaintenanceSelect = val => {
    setDirty(true)
    setHasMaintenance(val)
    setHasMaintenanceValue(getNumbersFromString(get(policy, 'maintenance_duration') || '1y'))
    setHasMaintenancePeriod(getCharsFromString(get(policy, 'maintenance_duration') || '1y'))
    setHasMaintenanceError('')
  }

  const handleMaintenanceValueChange = val => {
    setDirty(true)
    setHasMaintenanceValue(val)
    debouncedValidateRequiredNumber(val).then(err => setHasMaintenanceError(err))
  }

  const handleMaintenancePeriodChange = val => {
    setDirty(true)
    setHasMaintenancePeriod(val)
  }

  // ------------- PRODUCT FEATURES METHODS --------------------------------------------------------------------------------------------------------------------------
  const handleProductFeatureSelect = values => {
    setDirty(true)
    if (!selectedProductFeatures.length) {
      setSelectedProductFeatures(values)
      validateFeaturesConsumption(values)
      return true
    }
    const newSelectedList = values.map(v => {
      const isSelected = selectedProductFeatures.find(sf => sf.value === v.value)
      if (isSelected) {
        return isSelected
      }
      return v
    })
    setSelectedProductFeatures(newSelectedList)
    validateFeaturesConsumption(newSelectedList)
    return true
  }

  // ------------- CUSTOM FIELDS METHODS --------------------------------------------------------------------------------------------------------------------------
  const handleCustomFieldsSelect = values => {
    if (!isFeatureEnabled(platformFeatures.extra_custom_fields)) {
      Notification('error', __(defaultDisabledFeatureMessage), __(getDisabledMessage()))
      return false
    }

    setDirty(true)
    if (!selectedCustomFields.length) {
      setSelectedCustomFields(values)
      validateCustomFields(values)
      return true
    }
    const newSelectedList = values.map(v => {
      const isSelected = selectedCustomFields.find(sf => sf.value === v.value)
      if (isSelected) {
        return isSelected
      }
      return v
    })
    setSelectedCustomFields(newSelectedList)
    validateCustomFields(newSelectedList)
    return true
  }

  const handleFieldsEdit = values => {
    setDirty(true)
    setSelectedCustomFields(values)
    validateCustomFields(values)
  }

  const createPolicy = async data => {
    try {
      await createLicensePolicy(companyID, data)
      Notification('success', __('Changes saved successfully'), __('License policy created'))
      refetchPolicies()
      dispatch(getProducts(companyID))
      closeCb()
    } catch (err) {
      sendErrorReport(err, 'Cannot add a license policy', data)
      setLoading(false)
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      )
    }
  }

  const updatePolicy = async data => {
    const policyID = get(policy, 'id')
    try {
      await patchLicensePolicy(policyID, companyID, data)
      Notification('success', __('Changes saved successfully'), __('License policy updated'))
      refetchPolicies()
      dispatch(getProducts(companyID))
      closeCb()
    } catch (err) {
      sendErrorReport(err, 'Cannot edit license', data)
      setLoading(false)
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      )
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const isValid = await isFormValid()
    if (!isValid || isLoading) {
      return false
    }
    if (!isDirty) {
      closeCb()
      return false
    }

    setDirty(false)
    setLoading(true)
    const data = createPolicyData()

    if (!policy) {
      createPolicy(data)
    } else {
      updatePolicy(data)
    }
    return true
  }

  const handleClose = () => {
    if (!isDirty) {
      return closeCb()
    }
    return setDirtyFormAlertDisplay(true)
  }

  const features = mapPolicyProductFeatures(product, policy)
  const cfields = mapPolicyCustomFields(product, policy)
  const allowsAirGapped =
    isFeatureEnabled(platformFeatures.extra_air_gapped) &&
    isFeatureAvailable(platformFeatures.extra_air_gapped)

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      size='xl'
      title={policy ? __('Edit license policy') : __('Add license policy')}
    >
      <form className='LicensePolicyForm' onSubmit={handleSubmit}>
        {allowsAirGapped && !isUserBasedProduct && (
          <div className='air-gapped-switcher'>
            <Switcher
              inputId='is-air-gapped-switcher'
              isFeatureEnabled={isFeatureEnabled(platformFeatures.extra_air_gapped)}
              notEnabledMessage={getDisabledMessage()}
              checked={isAirGapped}
              handleChange={() => {
                setDirty(true)
                setAirGapped(prevState => !prevState)
              }}
              size='sm'
            />
            <Label inputId='is-air-gapped-switcher' text={__('Set as air-gapped')} />
          </div>
        )}
        {isAirGapped && (
          <Notice theme='info' size='sm' title='Air-gapped licensing'>
            <div>
              <div>
                {__(
                  'Air-gapped license policies are meant to be used in Zero Trust Network environments where usual offline licensing via file exchange is not possible.'
                )}
              </div>
              <div>
                {__('This license policies cannot be edited or deleted once they are created.')}
              </div>
              <div>{__('Check out the documentantion for air-gapped licensing')}</div>
            </div>
          </Notice>
        )}
        <Tabs forceRenderTabPanel>
          <TabsHeader>
            <Tab>{__('Policy values')}</Tab>
            <Tab>
              <CustomFormTab error={productFeaturesErrors} text={__('Product features')} />
            </Tab>
            <Tab>
              <CustomFormTab error={customFieldsError} text={__('Custom fields')} />
            </Tab>
          </TabsHeader>
          <TabContent>
            <div className='form-inner'>
              <div className='left'>
                <div className='form-row'>
                  <Label text={__('Name')} inputId='policy-name' />
                  <TextInput
                    id='policy-name'
                    value={name}
                    error={nameError}
                    handleChange={handleNameChange}
                  />
                </div>
                <div className='form-row'>
                  <Label text={__('Code')} inputId='policy-code' />
                  <TextInput
                    id='policy-code'
                    value={code}
                    error={codeError}
                    handleChange={handleCodeChange}
                    disabled={!!policy}
                  />
                </div>
                <div className='form-row'>
                  <Label text={__('Select license type')} inputId='default-license-type-selector' />
                  <LicenseTypeSelector
                    selectedLicense={selectedLicenseType}
                    handleLicenseChange={handleLicenseTypeChange}
                  />
                </div>
                <div className='form-row'>
                  {selectedLicenseType === licenseTypes.subscription && (
                    <div>
                      <Label text={__('Default license duration')} inputId='license-duration' />
                      <div className='flex-row'>
                        <NumberInput
                          handleChange={handleLicenseDurationValueChange}
                          value={licenseDurationValue}
                          min='0'
                          max='1000'
                        />
                        <Selector
                          options={periodOptions}
                          value={licenseDurationPeriod}
                          handleChange={handleLicenseDurationPeriodChange}
                        />
                      </div>
                      <InputErrorMessage text={licenseDurationError} />
                      <div className='form-row trial-checkbox'>
                        <Checkbox
                          label={__('Allow grace period')}
                          inputId='allow-grace-period'
                          checked={allowGracePeriod}
                          handleChange={handleAllowGracePeriod}
                        />
                      </div>
                      {allowGracePeriod && (
                        <div className='form-row'>
                          <Label
                            text={__('Grace period')}
                            inputId='grace-period'
                            description={__('Hours')}
                          />
                          <NumberInput
                            handleChange={handleGracePeriodChange}
                            value={gracePeriod}
                            error={gracePeriodError}
                            min='1'
                            max='2147483647'
                          />
                        </div>
                      )}
                      <Notice theme='warning' size='sm'>
                        <div className='actionable-notice'>
                          <div className='text'>
                            {__(
                              'LicenseSpring requires an integration to an external source of truth (like a recurring billing system). LicenseSpring updates the status of the license according to the status of the subscription, which is handled by a 3rd party. Without this integration, subscription licenses remain valid until otherwise specified'
                            )}
                          </div>
                        </div>
                      </Notice>
                    </div>
                  )}
                  {selectedLicenseType === licenseTypes.time_limited && !isTrial && (
                    <div className='section-row time-limited-options'>
                      <div className='radio-btns'>
                        <RadioBtn
                          name='time-limited-select'
                          label={__('Valid from activation:')}
                          inputId='valid_from'
                          description={__(
                            'Select the validity duration, which begins the moment the license has been activated'
                          )}
                          value={validityTypes.duration}
                          checked={selectedDurationType === validityTypes.duration}
                          handleChange={val => {
                            setDirty(true)
                            setSelectedDurationType(val)
                            setValidUntil(get(policy, 'validity_period') || '')
                            setValidUntilError('')
                          }}
                        />
                        <div>
                          <div className='flex-row'>
                            <NumberInput
                              handleChange={handleLicenseDurationValueChange}
                              value={licenseDurationValue}
                              min='0'
                              max='1000'
                              disabled={selectedDurationType === validityTypes.period}
                            />
                            <Selector
                              options={periodOptions}
                              value={licenseDurationPeriod}
                              handleChange={handleLicenseDurationPeriodChange}
                              disabled={selectedDurationType === validityTypes.period}
                            />
                          </div>
                          <InputErrorMessage text={licenseDurationError} />
                        </div>
                        <RadioBtn
                          name='time-limited-select'
                          inputId='valid_until'
                          label={__('Set end date')}
                          description={__(
                            'Set the date in the future at which the license is set to expire'
                          )}
                          value={validityTypes.period}
                          checked={selectedDurationType === validityTypes.period}
                          handleChange={val => {
                            setDirty(true)
                            setSelectedDurationType(val)
                            setLicenseDurationValue(
                              getNumbersFromString(get(policy, 'valid_duration') || '1y')
                            )
                            setLicenseDurationPeriod(
                              getCharsFromString(get(policy, 'valid_duration') || '1y')
                            )
                            setLicenseDurationError('')
                          }}
                        />
                        <DateInput
                          id='release'
                          handleChange={handleValidUntilChange}
                          value={validUntil}
                          error={validUntilError}
                          disabled={selectedDurationType === validityTypes.duration}
                        />
                      </div>
                    </div>
                  )}
                  {selectedLicenseType === licenseTypes.consumption && (
                    <>
                      <div className='form-row'>
                        <Label text={__('Max consumptions')} inputId='max-consumption' />
                        <NumberInput
                          handleChange={handleMaxConsumptionsChange}
                          value={maxConsumptions}
                          error={maxConsumptionsError}
                          min='1'
                          max='10000'
                          disabled={allowUnlimitedConsumptions}
                        />
                        <Checkbox
                          label={__('Allow unlimited consumptions')}
                          inputId='allow-unlimited-consumptions-checkbox'
                          checked={allowUnlimitedConsumptions}
                          handleChange={handleAllowUnlimitedConsumptions}
                        />
                      </div>
                      <div className='form-row'>
                        <Checkbox
                          label={__('Allow negative consumptions')}
                          inputId='allow-negative-consumptions-checkbox'
                          checked={allowNegativeConsumptions}
                          handleChange={val => {
                            setDirty(true)
                            setAllowNegativeConsumptions(val)
                          }}
                        />
                      </div>
                      <div className='form-row overage-options'>
                        <Checkbox
                          label={__('Allow overages')}
                          inputId='consumption-overages'
                          checked={allowOverages}
                          handleChange={handleAllowOveragesChange}
                          featureEnabled={isFeatureEnabled(platformFeatures.model_consumption)}
                          notEnabledMessage={getDisabledMessage()}
                        />
                      </div>
                      {allowOverages && (
                        <div className='form-row'>
                          <div className='radio-btns radio-btns-overages'>
                            <RadioBtn
                              name='overages-select'
                              inputId={overageTypes.unlimited}
                              label={__('Unlimited overages')}
                              value={overageTypes.unlimited}
                              checked={selectedOverageType === overageTypes.unlimited}
                              handleChange={handleUnlimitedOveragesSelect}
                            />
                            <RadioBtn
                              name='overages-select'
                              inputId={overageTypes.custom}
                              label={__('Max overages')}
                              value={overageTypes.custom}
                              checked={selectedOverageType === overageTypes.custom}
                              handleChange={handleMaxOveragesSelect}
                            />
                            <NumberInput
                              handleChange={handleMaxOveragesChange}
                              value={maxOverage}
                              min='0'
                              max='2147483647'
                              disabled={selectedOverageType === overageTypes.unlimited}
                            />
                            <InputErrorMessage text={maxOverageError} />
                          </div>
                        </div>
                      )}
                      <div className='form-row overage-options'>
                        <Checkbox
                          label={__('Reset consumption')}
                          inputId='consumption-reset'
                          checked={resetConsumption}
                          handleChange={handleResetConsumptionsChange}
                          featureEnabled={isFeatureEnabled(platformFeatures.model_consumption)}
                          notEnabledMessage={getDisabledMessage()}
                        />
                      </div>
                      {resetConsumption && (
                        <div className='form-row consumption-period-select overage-options'>
                          <Label text={__('Consumption period')} />
                          <Selector
                            options={consumptionPeriodOptions}
                            value={consumptionPeriod}
                            handleChange={handleConsumptionsPeriodChange}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className='form-row trial-checkbox'>
                  <Checkbox
                    label={__('Is trial')}
                    inputId='is-trial'
                    checked={isTrial}
                    handleChange={handleAllowTrialChange}
                  />
                </div>{' '}
                {isTrial && (
                  <div className='section-row'>
                    <div className='radio-btns'>
                      <RadioBtn
                        name='trial-type-select'
                        inputId={trialTypes.days}
                        label={__('Trial duration')}
                        value={trialTypes.days}
                        checked={selectedTrialType === trialTypes.days}
                        handleChange={handleSelectedTrialTypeChange}
                      />
                      <div className='flex-row'>
                        <NumberInput
                          handleChange={val => {
                            setDirty(true)
                            setTrialDays(val)
                            debouncedValidateRequiredNumber(val).then(err => setTrialDaysError(err))
                          }}
                          value={trialDays}
                          error={trialDaysError}
                          min='1'
                          max='1000'
                          disabled={selectedTrialType === trialTypes.endDate}
                        />
                        <Selector
                          options={[{ label: __('Days'), value: 'd' }]}
                          value='d'
                          handleChange={() => {}}
                          disabled={selectedTrialType === trialTypes.endDate}
                        />
                      </div>
                      <RadioBtn
                        name='trial-type-select'
                        inputId={trialTypes.endDate}
                        label={__('Trial until date:')}
                        value={trialTypes.endDate}
                        checked={selectedTrialType === trialTypes.endDate}
                        handleChange={handleSelectedTrialTypeChange}
                      />
                      <DateInput
                        id='trial-end-date'
                        handleChange={val => {
                          setDirty(true)
                          setTrialEndDate(val)
                          debouncedValidateDate(val).then(err => setTrialEndDateError(err))
                        }}
                        value={trialEndDate}
                        error={trialEndDateError}
                        disabled={selectedTrialType === trialTypes.days}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className='divider active' />
              <div className='right'>
                <div className='form-row max-activations'>
                  <Label text={__('Max activations')} inputId='max-activations' />
                  <NumberInput
                    id='max-activations'
                    handleChange={handleMaxActivationsChange}
                    value={maxActivations}
                    error={maxActivationsError}
                    min='1'
                    max='2147483647'
                    disabled={allowUnlimitedActivations}
                  />
                  <Checkbox
                    label={__('Allow unlimited activations')}
                    inputId='allow-unlimited-activations-checkbox'
                    checked={allowUnlimitedActivations}
                    handleChange={handleAllowUnlimitedActivations}
                  />
                </div>
                <div className='form-row'>
                  <Checkbox
                    label={__('Prevent device transfers')}
                    inputId='prevent-transfers-checkbox'
                    checked={preventMaxTransfers}
                    handleChange={handlePreventDeviceTransfers}
                    featureEnabled={isFeatureEnabled(platformFeatures.extra_max_transfers)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.extra_max_transfers)}
                    notAvailableMessage={notAvailableMaxTransfersMessage}
                  />
                </div>
                <div className='form-row'>
                  <Checkbox
                    label={__('Has device transfer limit')}
                    inputId='max-transfers-checkbox'
                    checked={hasMaxTransfers}
                    handleChange={handleMaxTransfersSelect}
                    featureEnabled={isFeatureEnabled(platformFeatures.extra_max_transfers)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.extra_max_transfers)}
                    notAvailableMessage={notAvailableMaxTransfersMessage}
                  />
                  {!!hasMaxTransfers && (
                    <NumberInput
                      handleChange={handleMaxTransfersChange}
                      value={maxTransfers}
                      error={maxTransfersError}
                      min='1'
                      max='2147483647'
                      id='max-transfers-input'
                      placeholder={__('Device transfer limit')}
                    />
                  )}
                </div>
                {isUserBasedProduct ? (
                  <div className='max-users'>
                    <div className='form-row row-max-license-users'>
                      <Label text={__('Max license users')} />
                      <NumberInput
                        handleChange={handleMaxUsersChange}
                        value={maxLicenseUsers}
                        error={maxLicenseUsersError}
                        min='0'
                        max='2147483647'
                        disabled={unlimitedMaxLicenseUsers}
                      />
                    </div>
                    <div className='form-row row-max-license-users-checkbox'>
                      <Checkbox
                        label={__('Unlimited users')}
                        description={__('Only when is floating cloud')}
                        inputId='max-license-users-checkbox'
                        checked={unlimitedMaxLicenseUsers}
                        handleChange={handleUnlimitedMaxUsersSelect}
                        disabled={!isFloatingCloud}
                      />
                    </div>
                  </div>
                ) : null}
                <div className='form-row prevent-vm'>
                  <Checkbox
                    label={__('Prevent virtual machine')}
                    inputId='product-prevent-vm'
                    checked={preventVm}
                    handleChange={handlePreventVmChange}
                    featureEnabled={isFeatureEnabled(platformFeatures.extra_prevent_vm)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.extra_prevent_vm)}
                    notAvailableMessage={notAvailablePreventVmMessage}
                  />
                </div>
                <div className='form-row floating'>
                  <Checkbox
                    label={__('Offline floating license')}
                    inputId='floating-license'
                    checked={isFloating}
                    handleChange={handleFloatingSelect}
                    featureEnabled={isFeatureEnabled(platformFeatures.model_floating_cloud)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.model_floating_cloud)}
                    notAvailableMessage={notAvailableFloatingMessage}
                  />
                </div>
                <div className='form-row floating'>
                  <Checkbox
                    label={__('Is floating cloud')}
                    inputId='floating-cloud-license'
                    checked={isFloatingCloud}
                    handleChange={handleFloatingCloudSelect}
                    featureEnabled={isFeatureEnabled(platformFeatures.model_floating_cloud)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.model_floating_cloud)}
                    notAvailableMessage={notAvailableFloatingMessage}
                  />
                </div>
                {(isFloating || isFloatingCloud) && (
                  <div className='floating-form-fields'>
                    <div className='form-row floating'>
                      <Label text={__('Max simultaneous license users')} />
                      <NumberInput
                        handleChange={handleFloatingUsersChange}
                        value={floatingUsers}
                        error={floatingUsersError}
                        min='1'
                        max='2147483647'
                      />
                    </div>
                    <div className='form-row floating'>
                      <Label
                        text={__('Floating timeout')}
                        description={__(
                          'If the license issued is a floating license, the floating timeout is the time interval in minutes that the end users application will need to perform a license check in order to remain registered to the license.'
                        )}
                      />
                      <NumberInput
                        handleChange={handleFloatingTimeoutChange}
                        value={floatingTimeout}
                        error={floatingTimeoutError}
                        min='1'
                        max='2147483647'
                      />
                    </div>
                    <Checkbox
                      label={__('Can borrow license')}
                      inputId='can-borrow-license'
                      checked={canBorrow}
                      handleChange={handleCanBorrowChange}
                      featureEnabled={isFeatureEnabled(platformFeatures.extra_license_borrowing)}
                      notEnabledMessage={getDisabledMessage()}
                      featureAvailable={isFeatureAvailable(
                        platformFeatures.extra_license_borrowing
                      )}
                      notAvailableMessage={notAvailableFloatingMessage}
                    />
                    {canBorrow && (
                      <div className='form-row row-expanded'>
                        <Label text={__('Max borrow time')} description={__('Hours')} />
                        <NumberInput
                          handleChange={handleMaxBorrowTimeChange}
                          value={maxBorrowTime}
                          error={maxBorrowTimeError}
                          min='1'
                          max='2147483647'
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className='form-row'>
                  <Checkbox
                    label={__('Enable maintenance period')}
                    inputId='maintenance-period'
                    checked={hasMaintenance}
                    handleChange={handleEnableMaintenanceSelect}
                    featureEnabled={isFeatureEnabled(platformFeatures.deployment_maintenance)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.deployment_maintenance)}
                    notAvailableMessage={notAvailableMaintenanceMessage}
                  />
                </div>
                {hasMaintenance && (
                  <div className='form-row'>
                    <div className='flex-row'>
                      <NumberInput
                        handleChange={handleMaintenanceValueChange}
                        value={hasMaintenanceValue}
                        min='0'
                        max='1000'
                      />
                      <Selector
                        options={periodOptions}
                        value={hasMaintenancePeriod}
                        handleChange={handleMaintenancePeriodChange}
                      />
                    </div>
                    <InputErrorMessage text={hasMaintenanceError} />
                  </div>
                )}
                <div className='form-row'>
                  <Checkbox
                    label={__('Require hardware key')}
                    inputId='require-hw-key'
                    checked={isHwKeyAuth}
                    handleChange={val => {
                      setDirty(true)
                      setHwKeyAuth(val)
                    }}
                    featureEnabled={isFeatureEnabled(platformFeatures.extra_hardware_token)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.extra_hardware_token)}
                    notAvailableMessage={notAvailableHwKeyMessage}
                  />
                </div>
                <div className='form-row'>
                  <Label inputId='metadata-input' text={__('Metadata JSON')} />
                  <TextArea
                    handleChange={val => {
                      setDirty(true)
                      setMetadata(val)
                      debouncedValidateJSON(val).then(err => setMetadataError(err))
                    }}
                    id='metadata-input'
                    type='metadata'
                    value={metadata}
                    error={metadataError}
                    rows='4'
                    disabled={!isEnterprisePlan}
                  />
                </div>
              </div>
            </div>
          </TabContent>
          <TabContent>
            <div className='ProductFeatures-field'>
              <div className='ProductFeatures-selector'>
                <Label text={__('Select product features')} />
                <SearchableCheckbox
                  text={__('Features list')}
                  // options={productFeatures}
                  options={features}
                  value={selectedProductFeatures}
                  onChangeCallback={handleProductFeatureSelect}
                  onMenuClose={() => {}}
                  // featureEnabled={isFeatureEnabled(platformFeatures.extra_feature_licensing)}
                  // notEnabledMessage={getDisabledMessage()}
                  featureAvailable={isFeatureAvailable(platformFeatures.extra_feature_licensing)}
                  notAvailableMessage={notAvailableFeaturesMessage}
                />
              </div>
              <div>
                <ProductFeaturesEditor
                  disableFeatureExpiry
                  selectedFeatures={selectedProductFeatures}
                  onFeaturesChange={data => {
                    setDirty(true)
                    setSelectedProductFeatures(data)
                  }}
                />
              </div>
            </div>
          </TabContent>
          <TabContent>
            <section className='CustomFieldsTab product-features-section'>
              <div className='CustomFields-field'>
                <div className='CustomFields-selector'>
                  <Label text={__('Select custom fields')} />
                  <CheckboxSelector
                    text={__('Custom fields list')}
                    options={cfields}
                    value={selectedCustomFields}
                    onChangeCallback={handleCustomFieldsSelect}
                    onMenuClose={() => {}}
                    featureAvailable={isFeatureAvailable(platformFeatures.extra_custom_fields)}
                    notAvailableMessage={notAvailableCustomFieldsMessage}
                  />
                </div>
                <div>
                  <CustomFieldsEditableTable
                    selectedCustomFields={selectedCustomFields}
                    setErrorState={val => setCustomFieldsError(val)}
                    handleFieldsEdit={handleFieldsEdit}
                  />
                </div>
              </div>
            </section>
          </TabContent>
        </Tabs>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </form>
    </Modal>
  )
}

LicensePolicyForm.propTypes = {
  companyID: PropTypes.number.isRequired,
  closeCb: PropTypes.func.isRequired,
  refetchPolicies: PropTypes.func.isRequired,
  policies: PropTypes.array,
  policy: PropTypes.object,
  product: PropTypes.object.isRequired
}

LicensePolicyForm.defaultProps = {
  policies: [],
  policy: null
}

export default LicensePolicyForm
