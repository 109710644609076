import React from 'react'
import { UserPortalData } from '../components'

const UserPortalPreferences = () => (
  <div className='UserPortalPreferences'>
    <UserPortalData />
  </div>
)

export default UserPortalPreferences
