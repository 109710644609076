import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  ConfirmationPopup,
  IconDelete,
  IconEdit,
  List,
  Notification,
  PermissionMissingNotificationTitle
} from 'shared/components'
import {
  isFeatureEnabled,
  getDisabledMessage,
  isFeatureAvailable,
  sendErrorReport,
  checkUserPermission
} from 'shared/helpers'
import { platformFeatures, userPermissions } from 'shared/constants'
import { fetchCustomFields, deleteCustomField, getAllProducts } from 'src/product/actions'
import { CustomFieldForm } from '../../../Forms'

const CustomFields = ({ companyID, product }) => {
  const canManageProducts = checkUserPermission(userPermissions.products_write)
  const dispatch = useDispatch()
  const productID = get(product, 'id')
  const planType = useSelector(state => get(state, 'company.details.plan_type'))
  const freePlanMessage = __('Custom Fields are not available in your plan.')
  const standardPlanMessage = __('Your plan allows for one custom field.')

  const [isLoading, setLoading] = useState(true)
  const [customFields, setCustomFields] = useState([])
  const [showCustomFieldsForm, setCustomFieldsFormDisplay] = useState(false)
  const [fieldToDelete, setFieldToDelete] = useState(null)
  const [isDeleteConfirmationDisplayed, setDeleteConfirmationDisplay] = useState(false)
  const [isDeleting, setDeleting] = useState(false)
  const [fieldToEdit, setFieldToEdit] = useState(null)

  // TODO add serverside pagination
  const getCustomFields = useCallback(() => {
    fetchCustomFields(productID, companyID)
      .then(res => {
        setLoading(false)
        setCustomFields(get(res, 'data') || [])
      })
      .catch(err => {
        setLoading(false)
        sendErrorReport(err, 'Cannot fetch product custom fields')
        Notification(
          'error',
          __('Error occured'),
          __('We could not load the product custom fields.')
        )
      })
  }, [productID])

  const handleCustomFieldDelete = () => {
    const fieldToDeleteID = get(fieldToDelete, 'id')
    setDeleting(true)
    deleteCustomField(fieldToDeleteID, companyID)
      .then(() => {
        getCustomFields()
        setDeleteConfirmationDisplay(false)
        setFieldToDelete(null)
        setDeleting(false)
        Notification('success', __('Custom field deleted'))
        dispatch(getAllProducts(companyID))
      })
      .catch(err => {
        setDeleting(false)
        sendErrorReport(err, 'Cannot delete product custom fields')
        Notification(
          'error',
          __('Error occured'),
          __('We could not delete the product custom fields.')
        )
      })
  }

  useEffect(() => {
    getCustomFields()
  }, [getCustomFields])

  const handleManageCustomFieldClick = cb => {
    if (!canManageProducts) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.products_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  return (
    <div className='CustomFields custom-fields-tab'>
      <div className='Tab-header'>
        {__('Custom Fields allow you to push information from the platform to your application.')}
      </div>
      <Button
        featureEnabled={isFeatureEnabled(platformFeatures.extra_custom_fields)}
        notEnabledMessage={getDisabledMessage()}
        featureAvailable={isFeatureAvailable(
          platformFeatures.extra_custom_fields,
          customFields.count
        )}
        notAvailableMessage={planType === 'free' ? freePlanMessage : standardPlanMessage}
        ctaText={__('Upgrade to add more custom fields.')}
        onClick={() => handleManageCustomFieldClick(setCustomFieldsFormDisplay)}
        size='sm'
        theme='info'
      >
        {__('Add custom field')}
      </Button>
      <List
        columns={[
          { accessor: 'name', Header: __('Name') },
          { accessor: 'default_value', Header: __('Value') },
          {
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: rowData => (
              <Button
                featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
                featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
                notEnabledMessage={getDisabledMessage()}
                className='edit-button'
                onClick={() =>
                  handleManageCustomFieldClick(() => {
                    setFieldToEdit(rowData.original)
                    setCustomFieldsFormDisplay(true)
                  })
                }
                type='button'
              >
                <IconEdit height='16px' width='16px' />
              </Button>
            ),
            maxWidth: 50
          },
          {
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: rowData => (
              <Button
                featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
                notEnabledMessage={getDisabledMessage()}
                className='edit-button'
                onClick={() =>
                  handleManageCustomFieldClick(() => {
                    setFieldToDelete(rowData.original)
                    setDeleteConfirmationDisplay(true)
                  })
                }
                type='button'
              >
                <IconDelete height='16px' width='16px' color='#ee5253' />
              </Button>
            ),
            maxWidth: 50
          }
        ]}
        data={get(customFields, 'results') || []}
        minRows={2}
        pageSize={20}
        loading={isLoading}
      />
      {showCustomFieldsForm && (
        <CustomFieldForm
          closeCb={() => {
            setCustomFieldsFormDisplay(false)
            setFieldToEdit(null)
          }}
          refetchCustomFields={getCustomFields}
          productId={productID}
          updateProducts={() => dispatch(getAllProducts(companyID))}
          fieldToEdit={fieldToEdit}
          companyID={companyID}
        />
      )}
      {isDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setDeleteConfirmationDisplay(false)
            setFieldToDelete(null)
          }}
          confirmCb={handleCustomFieldDelete}
          title={`${__('Are you sure you want to delete custom field:')} ${get(
            fieldToDelete,
            'name'
          )}?`}
          confirmText={__('Delete')}
          theme='error'
          disabled={isDeleting}
        />
      )}
    </div>
  )
}

CustomFields.propTypes = {
  companyID: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired
}

export default CustomFields
