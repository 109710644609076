import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { generatePass, sendErrorReport } from 'shared/helpers'
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  PhoneNumberInput,
  RadioBtn,
  Selector,
  AsyncSelector,
  InputErrorMessage
} from 'shared/components'
import { validateEmail, debouncedValidateEmail } from 'shared/validation'
import { searchType } from 'shared/constants'
import api from 'shared/api'
import { addOrderManager, createLicenseManager } from 'src/order/actions'
import { patchLicenseUser } from 'src/license/actions'
import './styles.scss'

const OrderManagerForm = ({ companyID, closeCb, confirmCb, orderID, orderManagers, manager }) => {
  const managerToEditID = get(manager, 'id')

  const getInitialType = () => {
    if (!orderID) {
      return 'new'
    }
    if (manager) {
      return 'new'
    }
    return 'existing'
  }

  const [selectedFormType, setSelectedFormType] = useState(getInitialType())
  // existing
  const [searchBy, setSearchBy] = useState(searchType.trueEmail)
  const [existingManager, setExistingManager] = useState()
  const [existingManagerError, setExistingManagerError] = useState('')
  // new
  const [isLoading, setLoading] = useState(false)
  const [isDirty, setDirty] = useState(false)
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false)
  const [email, setEmail] = useState(get(manager, 'true_email') || '')
  const [emailError, setEmailError] = useState('')
  const [firstName, setFirstName] = useState(get(manager, 'first_name') || '')
  const [lastName, setLastName] = useState(get(manager, 'last_name') || '')
  const [phoneNumber, setPhoneNumber] = useState(get(manager, 'phone_number') || '')
  const [password, setPassword] = useState(generatePass())

  const checkManagersList = val => {
    if (!val) {
      return false
    }
    const managersEmailList = orderManagers.map(om => om.true_email.toLowerCase())
    const doesEmailExist = managersEmailList.includes(val.toLowerCase())
    return doesEmailExist
  }

  const getNewManagersList = newEntry => {
    if (!manager) {
      return [...orderManagers, newEntry]
    }
    const updatedList = orderManagers.map(om => {
      if (get(om, 'id') === managerToEditID) {
        return newEntry
      }
      return om
    })
    return updatedList
  }

  const handleEmailChange = val => {
    const isManagerAlreadyAdded = checkManagersList(val)

    if (!manager && isManagerAlreadyAdded) {
      setDirty(true)
      setEmail(val)
      setEmailError(__('Manager with this email is already added to the list'))
      return false
    }

    setDirty(true)
    setEmail(val)
    debouncedValidateEmail(val).then(err => setEmailError(err))
    return true
  }

  const handleEmailValidation = async () => {
    const isManagerAlreadyAdded = checkManagersList(email)
    if (!manager && isManagerAlreadyAdded) {
      setEmailError(__('Manager with this email is already added to the list'))
      return false
    }

    setLoading(true)
    let errors
    try {
      errors = await validateEmail(email)
      setEmailError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate order manager form email value', {
        value: email
      })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const handleExistingManagerChange = val => {
    const isManagerAlreadyAdded = checkManagersList(get(val, 'true_email'))
    if (isManagerAlreadyAdded) {
      setDirty(true)
      setExistingManager(val)
      setExistingManagerError(__('Manager with this email is already added to the list'))
      return false
    }

    setDirty(true)
    setExistingManager(val)
    setExistingManagerError('')
    return true
  }

  const isFormValid = async () => {
    if (selectedFormType === 'new') {
      const isEmailValid = await handleEmailValidation()
      return isEmailValid
    }

    if (!existingManager) {
      setExistingManagerError(__('Please select an existing manager'))
      return false
    }

    const isManagerAlreadyAdded = checkManagersList(get(existingManager, 'true_email'))
    if (isManagerAlreadyAdded) {
      setExistingManagerError(__('Manager with this email is already added to the list'))
      return false
    }

    return true
  }

  const handleSubmitSuccess = res => {
    const newManager = get(res, 'data')
    const newList = getNewManagersList(newManager)
    confirmCb(newList)
    Notification('success', __('Changes saved successfully'))
    return true
  }

  const handleSubmitError = (err, data, msg) => {
    sendErrorReport(err, msg, data)
    setLoading(false)
    Notification(
      'error',
      __('Your changes were not saved'),
      __('There was an error while saving your changes')
    )
    return false
  }

  const updateExistingManager = data => {
    patchLicenseUser(managerToEditID, companyID, data)
      .then(res => handleSubmitSuccess(res))
      .catch(err => handleSubmitError(err, data, 'Cannot update license manager'))
  }

  const createNewManager = data => {
    if (!orderID) {
      createLicenseManager(companyID, data)
        .then(res => handleSubmitSuccess(res))
        .catch(err => handleSubmitError(err, data, 'Cannot create new license manager'))
    } else {
      addOrderManager(orderID, companyID, data)
        .then(res => handleSubmitSuccess(res))
        .catch(err => handleSubmitError(err, data, 'Cannot create new license manager'))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const isValid = await isFormValid()
    if (!isValid || isLoading) {
      return false
    }

    setLoading(true)
    let data

    if (existingManager) {
      data = {
        email: get(existingManager, 'true_email'),
        first_name: get(existingManager, 'first_name') || '',
        last_name: get(existingManager, 'last_name') || '',
        phone_number: get(existingManager, 'phone_number') || ''
      }
    } else {
      data = {
        email,
        first_name: firstName || '',
        last_name: lastName || '',
        phone_number: phoneNumber || '',
        password: password || undefined
      }
    }

    if (!manager) {
      createNewManager(data)
    } else {
      updateExistingManager(data)
    }
    return true
  }

  const handleSelectedFormType = val => {
    setDirty(true)
    setSelectedFormType(val)
    setEmail('')
    setEmailError('')
    setFirstName('')
    setLastName('')
    setPhoneNumber('')
    setPassword(generatePass())
    setExistingManager()
    setExistingManagerError('')
  }

  const handleClose = () => {
    if (!isDirty) {
      return closeCb()
    }
    return setDirtyFormDisplay(true)
  }

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      // size="sm"
      title={manager ? __('Edit license manager') : __('Add license manager')}
    >
      <form className='OrderManagerForm' onSubmit={handleSubmit}>
        {!manager && !!orderID ? (
          <div className='form-row'>
            <RadioBtn
              name='form-type-select'
              inputId='managers-existing'
              label={__('Select existing manager')}
              value='existing'
              checked={selectedFormType === 'existing'}
              handleChange={handleSelectedFormType}
            />
            {selectedFormType === 'existing' && (
              <div className='existing-manager'>
                <div className='search'>
                  <div className='search-manager-selector'>
                    <Selector
                      handleChange={val => setSearchBy(val)}
                      options={[
                        {
                          label: __('Search by email'),
                          value: searchType.trueEmail
                        },
                        {
                          label: __('Search by first name'),
                          value: searchType.firstName
                        },
                        {
                          label: __('Search by last name'),
                          value: searchType.lastName
                        }
                      ]}
                      value={searchBy}
                    />
                  </div>
                  <div className='manager-selector'>
                    <AsyncSelector
                      isClearable
                      valueKey='id'
                      labelKey='email'
                      getOptionLabel={o => {
                        if (searchBy === 'first_name' || searchBy === 'last_name') {
                          let label
                          if (o.first_name && o.last_name) {
                            label = `${o.first_name} ${o.last_name}`
                          } else if (o.email) {
                            label = o.email
                          } else {
                            label = o.id
                          }
                          return label
                        }

                        let label
                        if (o.true_email) {
                          label = o.true_email
                        } else if (o.first_name && o.last_name) {
                          label = `${o.first_name} ${o.last_name}`
                        } else {
                          label = o.id
                        }
                        return label
                      }}
                      id='manager-existing-select'
                      placeholder={__('Select existing manager')}
                      fetchOptions={val =>
                        api.get(
                          `/api/v1/license-users/?company=${companyID}&permissions__isnull=0&limit=50&order_by=email&${searchBy}__icontains=${val}`
                        )
                      }
                      fetchInitialOptions={() =>
                        api.get(
                          `/api/v1/license-users/?company=${companyID}&permissions__isnull=0&limit=50&order_by=email`
                        )
                      }
                      handleChange={val => handleExistingManagerChange(val)}
                      value={get(existingManager, 'id') || ''}
                    />
                  </div>
                </div>
                <InputErrorMessage text={existingManagerError} />
              </div>
            )}
            <RadioBtn
              name='form-type-select'
              inputId='managers-new'
              label={__('Create new manager')}
              value='new'
              checked={selectedFormType === 'new'}
              handleChange={handleSelectedFormType}
            />
          </div>
        ) : null}
        {selectedFormType === 'new' && (
          <>
            <div className='form-row'>
              <Label text={__('Email')} inputId='email' />
              <TextInput
                id='email'
                disabled={!!manager}
                value={email}
                error={emailError}
                handleChange={handleEmailChange}
              />
            </div>
            <div className='form-row'>
              <Label text={__('First Name')} inputId='firstname' />
              <TextInput
                id='firstname'
                value={firstName}
                handleChange={val => {
                  setDirty(true)
                  setFirstName(val)
                }}
              />
            </div>
            <div className='form-row'>
              <Label text={__('Last Name')} inputId='lastname' />
              <TextInput
                id='lastname'
                value={lastName}
                handleChange={val => {
                  setDirty(true)
                  setLastName(val)
                }}
              />
            </div>
            <div className='form-row'>
              <Label text={__('Phone Number')} inputId='phone' />
              <PhoneNumberInput
                value={phoneNumber}
                handleChange={val => {
                  setDirty(true)
                  setPhoneNumber(val)
                }}
              />
            </div>
          </>
        )}
        {!manager && selectedFormType === 'new' && (
          <div className='form-row'>
            <Label text={__('Password')} inputId='password' />
            <TextInput
              id='password'
              value={password}
              handleChange={val => {
                setDirty(true)
                setPassword(val)
              }}
            />
          </div>
        )}
      </form>
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

OrderManagerForm.propTypes = {
  companyID: PropTypes.number.isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  orderID: PropTypes.number,
  orderManagers: PropTypes.array,
  manager: PropTypes.object
}

OrderManagerForm.defaultProps = {
  orderManagers: [],
  manager: null,
  orderID: null
}

export default OrderManagerForm
