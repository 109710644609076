import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { NavLink, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Button,
  ContentLoader,
  ConfirmationPopup,
  DescriptionTable,
  Expander,
  Notice,
  Notification,
  StatusCard,
  PermissionMissingNotificationTitle,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  JsonView
} from 'shared/components'
import {
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  capitalizeFirstLetter,
  displayValue,
  formatDate,
  sendErrorReport,
  checkUserPermission
} from 'shared/helpers'
import { platformFeatures, userPermissions } from 'shared/constants'
import {
  deleteLicense,
  resetLicense,
  getBundleLicenses,
  disableLicense,
  enableLicense
} from 'src/license/actions'
import { BundleLicenseItem, EditBundleLicenseForm } from './components'
import { LicenseUsers } from '../LicenseContainer/components'
import './styles.scss'

const BundleLicenseContainer = ({ license, companyID, bundleProduct, refetchLicense }) => {
  const canManageLicenses = checkUserPermission(userPermissions.licenses_write)
  const history = useHistory()
  const licenseID = get(license, 'id')
  const productID = get(bundleProduct, 'id')
  const productName = get(bundleProduct, 'product_name')
  const productAuth = get(bundleProduct, 'authorization_method')
  const isAuthUserBased = productAuth === 'user'
  const products = useSelector(state => get(state, 'products.list') || [])

  const [bundleLicensesLoading, setBundleLicensesLoading] = useState(true)
  const [bundleLicenses, setBundleLicenses] = useState([])

  const [confirmationLoading, setConfirmationLoading] = useState(false)
  const [isEnableLicenseConfirmationDisplayed, setEnableLicenseConfirmationDisplay] = useState(
    false
  )
  const [isResetLicenseConfirmationDisplayed, setResetLicenseConfirmationDisplay] = useState(false)
  // license delete
  const [isDeleteLicenseIntentDisplayed, setDeleteLicenseIntentDisplay] = useState(false)
  const [isDeleteLicenseWarningDisplayed, setDeleteLicenseWarningDisplay] = useState(false)
  const [licenseDeleteLoading, setLicenseDeleteLoading] = useState(false)
  // edit bundle license
  const [isEditBundleLicenseFormDisplayed, setEditBundleLicenseFormDisplay] = useState(false)

  const fetchLicenses = useCallback(() => {
    getBundleLicenses(licenseID, companyID)
      .then(res => {
        const data = get(res, 'data.results') || []
        setBundleLicenses(data)
        setBundleLicensesLoading(false)
      })
      .catch(() => {
        setBundleLicensesLoading(false)
      })
  }, [licenseID])

  useEffect(() => {
    fetchLicenses()
  }, [fetchLicenses])

  const handleLicenseDelete = () => {
    setLicenseDeleteLoading(true)

    deleteLicense(licenseID, companyID)
      .then(() => {
        history.push(`/${companyID}/licenses`)
        Notification('success', __('License succesfully deleted'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete license')
        setLicenseDeleteLoading(false)
        Notification('error', __('Error occured'), __('Your changes were not saved'))
      })
  }

  const handleResetLicense = () => {
    setConfirmationLoading(true)

    resetLicense(licenseID, license, companyID)
      .then(() => {
        refetchLicense()
        setConfirmationLoading(false)
        setResetLicenseConfirmationDisplay(false)
        Notification('success', __('Changes saved successfully'), __('Your license has been reset'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot reset license')
        setConfirmationLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const patchEnabledStatus = () => {
    const currentStatus = get(license, 'enabled')
    setConfirmationLoading(true)
    const licenseData = { enabled: !currentStatus }

    if (currentStatus) {
      disableLicense(licenseID, companyID)
        .then(() => {
          setConfirmationLoading(false)
          refetchLicense()
          setEnableLicenseConfirmationDisplay(false)
          Notification(
            'success',
            __('Changes saved successfully'),
            !currentStatus ? __('License enabled') : __('License disabled')
          )
        })
        .catch(err => {
          sendErrorReport(err, 'Cannot enable license', licenseData)
          setConfirmationLoading(false)
          Notification(
            'error',
            __('Your changes were not saved'),
            __('There was an error while saving your changes')
          )
        })
    } else {
      enableLicense(licenseID, companyID)
        .then(() => {
          setConfirmationLoading(false)
          refetchLicense()
          setEnableLicenseConfirmationDisplay(false)
          Notification(
            'success',
            __('Changes saved successfully'),
            !currentStatus ? __('License enabled') : __('License disabled')
          )
        })
        .catch(err => {
          sendErrorReport(err, 'Cannot enable license', licenseData)
          setConfirmationLoading(false)
          Notification(
            'error',
            __('Your changes were not saved'),
            __('There was an error while saving your changes')
          )
        })
    }
  }

  const handleManageLicenseClick = cb => {
    if (!canManageLicenses) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.licenses_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  const formatBundleLicenseForExpander = licenses =>
    licenses.map(l => {
      const currentProduct = products.find(p => get(p, 'id') === get(l, 'product.id'))
      const prodName = get(currentProduct, 'product_name')
      const status = get(l, 'status')
      const licenseStatus = status === 'Active' ? 'success' : 'error'

      return {
        heading: <StatusCard text={prodName} status={licenseStatus} />,
        body: <BundleLicenseItem license={l} refetchLicense={fetchLicenses} />
      }
    })

  return (
    <div className='BundleLicenseContainer'>
      <div className='list-header'>
        <div />
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
            notEnabledMessage={getDisabledMessage()}
            theme='warning'
            onClick={() => handleManageLicenseClick(setResetLicenseConfirmationDisplay)}
          >
            {__('Reset')}
          </Button>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
            notEnabledMessage={getDisabledMessage()}
            theme={get(license, 'enabled') ? 'error' : 'success'}
            onClick={() => handleManageLicenseClick(setEnableLicenseConfirmationDisplay)}
          >
            {get(license, 'enabled') ? __('Disable') : __('Enable')}
          </Button>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
            notEnabledMessage={getDisabledMessage()}
            theme='default'
            onClick={() => handleManageLicenseClick(setDeleteLicenseIntentDisplay)}
          >
            {__('Delete license')}
          </Button>
        </div>
      </div>
      <Notice theme='info' size='sm' title={__('Bundle license')}>
        <div>
          <div>
            {__(
              'This is a bundle license. Check individual product licenses for more detailed overview.'
            )}
          </div>
          <div className='docs-link'>
            <p>{__('More details about product bundles can be found')}</p>
            <a
              href='https://docs.licensespring.com/docs/product-bundles'
              target='_blank'
              rel='noopener noreferrer'
            >
              {__('here')}
            </a>
          </div>
        </div>
      </Notice>
      <Tabs>
        <TabsHeader>
          <Tab>{__('License details')}</Tab>
          {isAuthUserBased && <Tab>{__('Users')}</Tab>}
        </TabsHeader>
        <TabContent>
          <div className='BundleLicenseDetails'>
            <Button
              featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
              featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
              notEnabledMessage={getDisabledMessage()}
              theme='default'
              size='sm'
              onClick={() => handleManageLicenseClick(setEditBundleLicenseFormDisplay)}
            >
              {__('Edit')}
            </Button>
            <DescriptionTable
              details={[
                {
                  label: __('Product'),
                  value: (
                    <NavLink to={`/${companyID}/products/${productID}`}>
                      {displayValue(productName)}
                    </NavLink>
                  )
                },
                {
                  label: __('Created on'),
                  value: formatDate(get(license, 'created_at'))
                },
                {
                  label: __('Authorization method'),
                  value: capitalizeFirstLetter(displayValue(productAuth))
                },
                {
                  label: __('Note'),
                  value: displayValue(get(license, 'note'))
                },
                {
                  label: __('Metadata'),
                  value: <JsonView value={get(license, 'metadata')} name='metadata' />
                }
              ]}
            />
          </div>
        </TabContent>
        {isAuthUserBased && (
          <TabContent>
            <LicenseUsers companyID={companyID} license={license} refetchLicense={refetchLicense} />
          </TabContent>
        )}
      </Tabs>
      <div className='BundleLicenses'>
        {bundleLicensesLoading ? (
          <ContentLoader />
        ) : (
          <Expander
            items={formatBundleLicenseForExpander(bundleLicenses)}
            allowMultipleExpanded
            allowZeroExpanded
          />
        )}
      </div>
      {isResetLicenseConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setResetLicenseConfirmationDisplay(false)}
          confirmCb={handleResetLicense}
          title={__('Are you sure you want to reset your license?')}
          confirmText={__('Reset')}
          theme='warning'
          disabled={confirmationLoading}
        />
      )}
      {isEnableLicenseConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setEnableLicenseConfirmationDisplay(false)}
          confirmCb={patchEnabledStatus}
          title={__(
            `Are you sure you want to ${
              get(license, 'enabled') ? __('disable') : __('enable')
            } your license?`
          )}
          confirmText={get(license, 'enabled') ? __('Disable') : __('Enable')}
          theme={get(license, 'enabled') ? 'error' : 'success'}
          disabled={confirmationLoading}
        >
          {get(license, 'enabled')
            ? __(
                'Bundle license, product licenses and all active license devices will be disabled.'
              )
            : __('Bundle license and product licenses will be enabled.')}
        </ConfirmationPopup>
      )}
      {isDeleteLicenseIntentDisplayed && (
        <ConfirmationPopup
          closeCb={() => setDeleteLicenseIntentDisplay(false)}
          confirmCb={() => setDeleteLicenseWarningDisplay(true)}
          title={__('Are you sure you want to delete this license?')}
          confirmText={__('Delete')}
          theme='error'
          disabled={licenseDeleteLoading}
        >
          {__('This action cannot be undone!')}
        </ConfirmationPopup>
      )}
      {isDeleteLicenseWarningDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setDeleteLicenseWarningDisplay(false)
            setDeleteLicenseIntentDisplay(false)
          }}
          confirmCb={handleLicenseDelete}
          title={__('Please, confirm that you really want to delete this license.')}
          confirmText={__('Delete')}
          theme='error'
          warning
          disabled={licenseDeleteLoading}
        />
      )}
      {isEditBundleLicenseFormDisplayed && (
        <EditBundleLicenseForm
          license={license}
          closeCb={() => setEditBundleLicenseFormDisplay(false)}
          confirmCb={() => {
            setEditBundleLicenseFormDisplay(false)
            refetchLicense()
          }}
        />
      )}
    </div>
  )
}

BundleLicenseContainer.propTypes = {
  companyID: PropTypes.number.isRequired,
  bundleProduct: PropTypes.object.isRequired,
  license: PropTypes.object.isRequired,
  refetchLicense: PropTypes.func.isRequired
}

export default BundleLicenseContainer
