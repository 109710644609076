import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Notification,
  PermissionMissingNotificationTitle,
  OAuthCredentials,
  OAuthModals
} from 'shared/components'
import { checkUserPermission } from 'shared/helpers'
import { userPermissions } from 'shared/constants'
import { useOAuth } from 'shared/hooks'
import {
  getOauthCustomerClient,
  createOauthCustomerClient,
  deleteOauthCustomerClient,
  createOauthCustomerClientSecret,
  rotateOauthCustomerClientSecret
} from 'src/customer/actions'

const CustomerOAuth = ({ customerId, companyID }) => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write)

  const handleManageSettingsClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb()
    return true
  }

  const {
    isLoading,
    loadingCreateClient,
    oAuthClient,
    isRotateSecretModalOpen,
    setRotateSecretModalOpen,
    isRotateConfirmationOpen,
    setRotateConfirmationOpen,
    isRefreshConfirmationOpen,
    setRefreshConfirmationOpen,
    isDeleteConfirmationOpen,
    setDeleteConfirmationOpen,
    rotateSecretData,
    setRotateSecretData,
    getOauthClient,
    createNewOauthClient,
    handleDeleteOauthClient,
    handleRefreshSecret,
    handleRotateSecret
  } = useOAuth({
    entityId: customerId,
    companyID,
    getClient: getOauthCustomerClient,
    createClient: createOauthCustomerClient,
    deleteClient: deleteOauthCustomerClient,
    refreshSecret: createOauthCustomerClientSecret,
    rotateSecret: rotateOauthCustomerClientSecret
  })

  const handleRotateClick = () => {
    setRotateSecretModalOpen(true)
  }

  const handleRefreshClick = () => {
    setRefreshConfirmationOpen(true)
  }

  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true)
  }

  return (
    <div className='OAuth'>
      <div className='row'>
        <h3>{__('Customer OAuth Configuration')}</h3>
        {!oAuthClient ? (
          <Button
            size='sm'
            theme='info'
            loading={loadingCreateClient}
            disabled={loadingCreateClient || isLoading}
            onClick={() => handleManageSettingsClick(createNewOauthClient)}
          >
            {__('Initialize OAuth')}
          </Button>
        ) : (
          <OAuthCredentials
            oAuthClient={oAuthClient}
            isLoading={isLoading}
            handleManageSettingsClick={handleManageSettingsClick}
            onRotateClick={handleRotateClick}
            onRefreshClick={handleRefreshClick}
            onDeleteClick={handleDeleteClick}
            setRefreshConfirmationOpen={setRefreshConfirmationOpen}
            setRotateConfirmationOpen={setRotateConfirmationOpen}
            setDeleteConfirmationOpen={setDeleteConfirmationOpen}
          />
        )}
      </div>

      <OAuthModals
        isLoading={isLoading}
        isDeleteConfirmationOpen={isDeleteConfirmationOpen}
        setDeleteConfirmationOpen={setDeleteConfirmationOpen}
        handleDeleteOauthClient={handleDeleteOauthClient}
        isRotateConfirmationOpen={isRotateConfirmationOpen}
        setRotateConfirmationOpen={setRotateConfirmationOpen}
        isRefreshConfirmationOpen={isRefreshConfirmationOpen}
        setRefreshConfirmationOpen={setRefreshConfirmationOpen}
        isRotateSecretModalOpen={isRotateSecretModalOpen}
        setRotateSecretModalOpen={setRotateSecretModalOpen}
        handleRotateSecret={handleRotateSecret}
        handleRefreshSecret={handleRefreshSecret}
        getOauthClient={getOauthClient}
        rotateSecretData={rotateSecretData}
        setRotateSecretData={setRotateSecretData}
      />
    </div>
  )
}
CustomerOAuth.propTypes = {
  customerId: PropTypes.string.isRequired,
  companyID: PropTypes.string.isRequired
}

export default CustomerOAuth
