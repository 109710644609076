import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams, Link } from 'react-router-dom'
import {
  Button,
  ContentLoader,
  ConfirmationPopup,
  DescriptionTable,
  IconCheckmark,
  IconDisabled,
  IconClipboard,
  JsonView,
  List,
  NotFound,
  Notification,
  Page,
  PermissionMissingNotificationTitle,
  StatusCard,
  Subtitle,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  TooltipHeader,
  Widget,
  WidgetsContainer,
  LabelTag,
  SelectedFeaturesTags
} from 'shared/components'
import { platformFeatures, defaultDateFormat, errorMsg, userPermissions } from 'shared/constants'
import {
  sendErrorReport,
  displayValue,
  formatDate,
  getDisabledMessage,
  getLicenseStatus,
  isFeatureAvailable,
  isFeatureEnabled,
  makeProductDictionary,
  copyText,
  checkUserPermission
} from 'shared/helpers'
import { checkCompanyConfigField } from 'shared/companyConfig'
import { addNewCustomerLabel } from 'src/company/actions'
import PlatformAuditLog from 'src/account/AuditLog/PlatformAuditLog'
import {
  fetchCustomer,
  deleteCustomer,
  fetchCustomerLicenses,
  fetchCustomerOrders,
  deleteLabelFromCustomer
} from 'src/customer/actions'
import { transformSort } from '../CustomersList/helpers'
import CustomerForm from '../components/CustomerForm'
import CustomerPasswordForm from '../components/CustomerPasswordForm'
import CustomerLabelsForm from '../components/CustomerLabelsForm'
import SelectCustomerAccount from '../components/SelectCustomerAccount'
import CustomerOAuth from '../CustomerOAuth'
import './styles.scss'

const CustomerContainer = () => {
  const canManageCustomers = checkUserPermission(userPermissions.customers_write)
  const { customerId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const companyLabels = useSelector(state => get(state, 'company.customerLabels'))
  const products = useSelector(state => get(state, 'products.list'))

  const [isLoading, setLoading] = useState(true)
  const [customer, setCustomer] = useState(null)
  // orders
  const [orders, setOrders] = useState([])
  const [ordersPage, setOrdersPage] = useState(0)
  const [ordersLoading, setOrdersLoading] = useState(true)
  const [orderSort, setOrderSort] = useState([
    {
      desc: true,
      id: 'created_at'
    }
  ])
  // licenses
  const [licenses, setLicenses] = useState([])
  const [licensesPage, setLicensesPage] = useState(0)
  const [licensesLoading, setLicensesLoading] = useState(true)
  const [licenseSort, setLicenseSort] = useState([
    {
      desc: true,
      id: 'created_at'
    }
  ])
  // actions
  const [showEditCustomerForm, setEditCustomerFormDisplay] = useState(false)
  const [showCustomerPasswordForm, setCustomerPasswordFormDisplay] = useState(false)
  const [showLabelForm, setLabelFormDisplay] = useState(false)
  const [labelToDelete, setLabelToDelete] = useState(null)
  const [showDeleteLabelConfirmation, setDeleteLabelConfirmationDisplay] = useState(false)
  const [deletingLabel, setDeletingLabel] = useState(false)
  const [isDeleteCustomerWarningDisplayed, setDeleteCustomerWarningDisplay] = useState(false)
  const [customerDeleteLoading, setCustomerDeleteLoading] = useState(false)

  const [isCompanyFormDisplayed, setCompanyFormDisplay] = useState(false)

  const getCustomer = useCallback(() => {
    fetchCustomer(customerId, companyID)
      .then(res => {
        const customerData = get(res, 'data')
        setCustomer(customerData)
        setLoading(false)
        return fetchCustomerOrders(companyID, get(customerData, 'id'), 0, transformSort(orderSort))
      })
      .then(res => {
        setOrders(get(res, 'data'))
        setOrdersLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot load customer details')
        setLoading(false)
        setOrdersLoading(false)
      })
  }, [companyID, customerId])

  useEffect(() => {
    getCustomer()
  }, [getCustomer])

  const updateOrders = (page, sort) => {
    fetchCustomerOrders(companyID, customerId, page, transformSort(sort))
      .then(res => {
        setOrders(get(res, 'data'))
        setOrdersLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get customer orders')
        setOrdersLoading(false)
      })
  }

  const updateLicenses = (page, sort) => {
    fetchCustomerLicenses(companyID, customerId, page, transformSort(sort))
      .then(res => {
        setLicenses(get(res, 'data'))
        setLicensesLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get customer licenses')
        setLicensesLoading(false)
      })
  }

  const handleOrdersPageChange = page => {
    setOrdersLoading(true)
    setOrdersPage(page)
    updateOrders(page, orderSort)
  }

  const handleOrderSortChange = newSorted => {
    setOrdersLoading(true)
    setOrderSort(newSorted)
    updateOrders(ordersPage, newSorted)
  }

  const handleLicenseSortChange = newSorted => {
    setLicensesLoading(true)
    setLicenseSort(newSorted)
    updateLicenses(licensesPage, newSorted)
  }

  const handleLicensePageChange = page => {
    setLicensesLoading(true)
    setLicensesPage(page)
    updateLicenses(page, licenseSort)
  }

  const handleTabChange = index => {
    if (index === 1 && !get(licenses, 'results')) {
      setLicensesLoading(true)
      updateLicenses(licensesPage, licenseSort)
    }
  }

  const redirectToOrderPage = rowData => {
    const orderId = get(rowData, 'original.id')
    history.push(`/${companyID}/orders/${orderId}`)
  }

  const redirectToLicensePage = rowData => {
    const orderId = get(rowData, 'original.order')
    const licenseId = get(rowData, 'original.id')
    history.push(`/${companyID}/orders/${orderId}/${licenseId}`)
  }

  const handleLabelRemove = () => {
    const labelToRemoveID = get(labelToDelete, 'id')
    setDeletingLabel(true)

    deleteLabelFromCustomer(labelToRemoveID, companyID)
      .then(() => {
        getCustomer()
        setDeletingLabel(false)
        setDeleteLabelConfirmationDisplay(false)
        setLabelToDelete(null)
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot load customer details')
        setDeletingLabel(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleCustomerDelete = () => {
    setCustomerDeleteLoading(true)

    deleteCustomer(customerId, companyID)
      .then(() => {
        setDeleteCustomerWarningDisplay(false)
        history.push(`/${companyID}/customers`)
        Notification('success', __('Customer deleted successfully'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete customer')
        setCustomerDeleteLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleFieldCopy = (val, desc) => {
    copyText(val)
    Notification('success', `${desc} ${__('copied to clipboard')}`)
  }

  const handleManageCustomerClick = cb => {
    if (!canManageCustomers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.customers_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  const createCustomerTitle = () => {
    const customerAccount = get(customer, 'customer_account')
    const customerLabels = get(customer, 'labels') || []
    const customerName =
      get(customer, 'first_name') || get(customer, 'last_name')
        ? `${get(customer, 'first_name')} ${get(customer, 'last_name')}`
        : ''
    const customerTitle =
      get(customer, 'email') ||
      customerName ||
      get(customer, 'reference') ||
      get(customerAccount, 'name') ||
      get(customer, 'company_name') ||
      '-'
    return (
      <div className='customer-title-container'>
        <div className='customer-title'>
          {__('Customer')}
          :&nbsp;
          <span>{customerTitle}</span>
          <button
            type='button'
            className='clipboard-btn'
            onClick={() => handleFieldCopy(customerTitle, __('Customer'))}
          >
            <IconClipboard width='21.5' height='20' viewBox='0 0 51.5 50' />
          </button>
        </div>
        {customerAccount && (
          <div className='customer-company-desc'>
            <Link to={`/${companyID}/customers/accounts/${get(customerAccount, 'id')}`}>
              {get(customerAccount, 'name')}
            </Link>
          </div>
        )}
        <div className='labels'>
          <>
            {customerLabels.map(l => (
              <Fragment key={get(l, 'id')}>
                <LabelTag
                  label={l}
                  handleLabelDelete={() =>
                    handleManageCustomerClick(() => {
                      setLabelToDelete(l)
                      setDeleteLabelConfirmationDisplay(true)
                    })
                  }
                />
              </Fragment>
            ))}
          </>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_customer)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_customer)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManageCustomerClick(setLabelFormDisplay)}
            theme='default'
            size='sm'
          >
            {__('Add label')}
          </Button>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <Page>
        <ContentLoader text={__('Getting customer details')} />
      </Page>
    )
  }
  if (!customer) {
    return (
      <Page>
        <NotFound />
      </Page>
    )
  }

  const mappedProducts = makeProductDictionary(products)

  const displayCustomerAccountLink = () => {
    const customerAccount = get(customer, 'customer_account')
    if (!customerAccount) {
      return '-'
    }

    return (
      <Link to={`/${companyID}/customers/accounts/${get(customerAccount, 'id')}`}>
        {get(customerAccount, 'name')}
      </Link>
    )
  }

  return (
    <Page title={createCustomerTitle()}>
      <div className='CustomerContainer'>
        <div className='list-header'>
          <div>
            <Button
              featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_customer)}
              featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_customer)}
              notEnabledMessage={getDisabledMessage()}
              onClick={() => handleManageCustomerClick(setEditCustomerFormDisplay)}
              theme='info'
            >
              {__('Edit customer')}
            </Button>
          </div>
          <div>
            <Button
              featureEnabled={isFeatureEnabled(platformFeatures.extra_account_based_licensing)}
              featureAvailable={isFeatureAvailable(platformFeatures.extra_account_based_licensing)}
              notEnabledMessage={getDisabledMessage()}
              onClick={() => handleManageCustomerClick(setCompanyFormDisplay)}
              theme='default'
            >
              {get(customer, 'customer_account') ? __('Change account') : __('Add to account')}
            </Button>
            <Button
              featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_customer)}
              featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_customer)}
              notEnabledMessage={getDisabledMessage()}
              onClick={() => handleManageCustomerClick(setDeleteCustomerWarningDisplay)}
              theme='error'
            >
              {__('Delete customer')}
            </Button>
          </div>
        </div>
        <WidgetsContainer>
          <Widget label={__('Total orders')} value={get(customer, 'total_orders')} />
          <Widget label={__('Total licenses')} value={get(customer, 'total_licenses')} />
        </WidgetsContainer>
        <div className='CustomerContainer'>
          <DescriptionTable
            details={[
              {
                label: __('Email'),
                value: displayValue(get(customer, 'email'))
              },
              {
                label: __('First Name'),
                value: displayValue(get(customer, 'first_name'))
              },
              {
                label: __('Last Name'),
                value: displayValue(get(customer, 'last_name'))
              },
              { label: __('Account'), value: displayCustomerAccountLink() },
              {
                label: __('Address'),
                value: displayValue(get(customer, 'address'))
              },
              { label: __('City'), value: displayValue(get(customer, 'city')) },
              {
                label: __('State / Province'),
                value: displayValue(get(customer, 'state'))
              },
              {
                label: __('Country'),
                value: displayValue(get(customer, 'country'))
              },
              {
                label: __('Zipcode / Postcode'),
                value: displayValue(get(customer, 'postcode'))
              },
              {
                label: __('Phone Number'),
                value: displayValue(get(customer, 'phone'))
              },
              {
                label: __('Company Name'),
                value: displayValue(get(customer, 'company_name'))
              },
              {
                label: __('Reference'),
                value: displayValue(get(customer, 'reference'))
              },
              {
                label: __('Metadata'),
                value: <JsonView value={get(customer, 'metadata')} name='metadata' />
              }
            ]}
          />
        </div>
        <div className='lists-cont'>
          <Tabs onSelect={handleTabChange}>
            <TabsHeader>
              <Tab>{__('Orders')}</Tab>
              <Tab>{__('Licenses')}</Tab>
              <Tab>{__('OAuth')}</Tab>
            </TabsHeader>
            <TabContent>
              <div className='first-tab'>
                <List
                  clickable
                  columns={[
                    {
                      accessor: 'created_at',
                      Header: __('Created on'),
                      Cell: rowData => formatDate(rowData.value),
                      width: 180
                    },
                    {
                      accessor: 'store_id',
                      Header: __('Order ID'),
                      Cell: rowData => displayValue(rowData.value),
                      minWidth: 200
                    },
                    {
                      accessor: 'campaign_params',
                      Header: __('Campaign parameters'),
                      Cell: rowData => displayValue(rowData.value)
                    },
                    {
                      accessor: 'download_id',
                      Header: __('Download ID'),
                      Cell: rowData => displayValue(rowData.value)
                    },
                    {
                      accessor: 'type',
                      Header: __('Type'),
                      Cell: rowData => displayValue(rowData.value)
                    },
                    {
                      accessor: 'has_active_licenses',
                      Header: __('Has active licenses'),
                      headerClassName: 'text-center',
                      className: 'text-center',
                      Cell: rowData =>
                        rowData.value ? (
                          <IconCheckmark color='#10ac84' height='14px' />
                        ) : (
                          <IconDisabled color='red' height='14px' />
                        )
                    }
                  ]}
                  data={get(orders, 'results') || []}
                  loading={ordersLoading}
                  manual
                  page={ordersPage}
                  pages={Math.ceil(get(orders, 'count') / 20)}
                  minRows={2}
                  showPagination
                  defaultSorted={orderSort}
                  onSortedChange={handleOrderSortChange}
                  onPageChange={handleOrdersPageChange}
                  handleClick={rowData => redirectToOrderPage(rowData)}
                />
              </div>
            </TabContent>
            <TabContent>
              <div className='second-tab'>
                <List
                  clickable
                  columns={[
                    {
                      accessor: 'created_at',
                      Header: __('Created on'),
                      Cell: rowData => formatDate(rowData.value),
                      width: 160
                    },
                    {
                      accessor: 'license_key',
                      Header: __('License key'),
                      Cell: rowData => {
                        const isBundle = get(rowData, 'original.bundle_license')
                        if (isBundle) return '-'
                        const licenseProduct = products.find(
                          p => p.id === get(rowData, 'original.product.id')
                        )
                        const isUserAuth = get(licenseProduct, 'authorization_method') === 'user'
                        if (isUserAuth) {
                          const licenseUsers = get(rowData, 'original.license_users') || []
                          const usersNum = licenseUsers.length
                          if (usersNum > 0) {
                            const firstUser = get(licenseUsers, '[0].true_email')
                            const isSingleUser = usersNum === 1
                            if (isSingleUser) {
                              return `${firstUser}`
                            }
                            return `${firstUser} (+${usersNum - 1})`
                          }
                          return __(errorMsg.notAssigned)
                        }

                        const licenseKey = get(rowData, 'original.license_key')
                        if (licenseKey) {
                          return displayValue(licenseKey)
                        }
                        return '-'
                      },
                      width: 200,
                      sortable: false
                    },
                    {
                      accessor: 'product.id',
                      Header: __('Product'),
                      Cell: rowData => displayValue(mappedProducts[rowData.value]),
                      maxWidth: 250
                    },
                    {
                      accessor: 'product_features',
                      Header: __('Product features'),
                      headerClassName: 'text-center',
                      Cell: rowData => {
                        const isBundle = get(rowData, 'original.is_bundle')
                        if (isBundle) return '-'
                        return <SelectedFeaturesTags features={get(rowData, 'value') || []} />
                      },
                      sortable: false
                    },
                    {
                      accessor: 'license_type',
                      Header: __('License Type'),
                      Cell: rowData => {
                        const isBundle = get(rowData, 'original.is_bundle')
                        if (isBundle) return '-'
                        return displayValue(rowData.value)
                      },
                      maxWidth: 200
                    },
                    {
                      accessor: 'validity_period',
                      Header: () => TooltipHeader(__('Expiration Date')),
                      Cell: rowData => {
                        const isBundle = get(rowData, 'original.is_bundle')
                        if (isBundle) return '-'
                        return formatDate(rowData.value, defaultDateFormat)
                      },
                      maxWidth: 200
                    },
                    {
                      accessor: 'times_activated',
                      Header: __('Times activated'),
                      Cell: rowData => {
                        const isBundle = get(rowData, 'original.is_bundle')
                        if (isBundle) return '-'
                        return displayValue(rowData.value)
                      },
                      maxWidth: 150
                    },
                    {
                      accessor: 'is_trial',
                      className: 'text-center',
                      Header: __('Is trial'),
                      headerClassName: 'text-center',
                      Cell: rowData => {
                        const isBundle = get(rowData, 'original.is_bundle')
                        if (isBundle) return '-'
                        return rowData.value ? (
                          <IconCheckmark color='#10ac84' height='14px' />
                        ) : (
                          <IconDisabled color='#aaa' height='14px' />
                        )
                      },
                      width: 80,
                      show: checkCompanyConfigField(companyID, 'isTrial')
                    },
                    {
                      accessor: 'enabled',
                      className: 'text-center',
                      Header: __('Status'),
                      headerClassName: 'text-center',
                      Cell: rowData => {
                        const isBundle = get(rowData, 'original.is_bundle')
                        if (isBundle) return '-'
                        const status = getLicenseStatus(
                          get(rowData, 'original.enabled'),
                          get(rowData, 'original.active')
                        )
                        return (
                          <StatusCard
                            status={status.positive ? 'success' : 'error'}
                            text={__(status.message)}
                          />
                        )
                      },
                      minWidth: 100
                    }
                  ]}
                  data={get(licenses, 'results', [])}
                  loading={licensesLoading}
                  manual
                  page={licensesPage}
                  pages={Math.ceil(get(licenses, 'count') / 20)}
                  minRows={2}
                  showPagination
                  defaultSorted={licenseSort}
                  onSortedChange={handleLicenseSortChange}
                  onPageChange={handleLicensePageChange}
                  handleClick={rowData => redirectToLicensePage(rowData)}
                />
              </div>
            </TabContent>
            <TabContent>
              <CustomerOAuth customerId={customerId} companyID={companyID} />
            </TabContent>
          </Tabs>
        </div>
        <div className='Logs'>
          <Subtitle text={__('Logs')} />
          <PlatformAuditLog customer={customer} />
        </div>
      </div>
      {showEditCustomerForm && (
        <CustomerForm
          closeForm={() => setEditCustomerFormDisplay(false)}
          updateCustomer={getCustomer}
          customer={customer}
          company={companyID}
        />
      )}
      {showCustomerPasswordForm && (
        <CustomerPasswordForm
          closeForm={() => setCustomerPasswordFormDisplay(false)}
          customer={customer}
          companyID={companyID}
        />
      )}
      {showLabelForm && (
        <CustomerLabelsForm
          closeCb={() => setLabelFormDisplay(false)}
          customer={customer}
          companyLabels={companyLabels}
          companyID={companyID}
          refetchCustomer={getCustomer}
          updateCompanyLabels={data => dispatch(addNewCustomerLabel(data))}
        />
      )}
      {showDeleteLabelConfirmation && (
        <ConfirmationPopup
          closeCb={() => {
            setLabelToDelete(null)
            setDeleteLabelConfirmationDisplay(false)
          }}
          confirmCb={handleLabelRemove}
          title={`${__('Are you sure you want to remove this label from the customer')}?`}
          confirmText={__('Remove')}
          theme='error'
          disabled={deletingLabel}
        >
          <strong>{get(labelToDelete, 'label')}</strong>
        </ConfirmationPopup>
      )}
      {isDeleteCustomerWarningDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setDeleteCustomerWarningDisplay(false)
          }}
          confirmCb={handleCustomerDelete}
          title={__('Are you sure you want to delete this customer?')}
          confirmText={__('Delete')}
          theme='error'
          warning
          disabled={customerDeleteLoading}
        />
      )}
      {isCompanyFormDisplayed && (
        <SelectCustomerAccount
          closeForm={() => setCompanyFormDisplay(false)}
          customerID={Number(customerId)}
          company={companyID}
          hasAccount={!!get(customer, 'customer_account')}
          updateCustomer={() => {
            setCompanyFormDisplay(false)
            getCustomer()
          }}
        />
      )}
    </Page>
  )
}

export default CustomerContainer
