import React from 'react'
import PropTypes from 'prop-types'
import { ContentLoader, IconLock, Notification, Tooltip } from 'shared/components'
import { defaultDisabledFeatureMessage } from 'shared/constants'
import './styles.scss'

const Button = ({
  children,
  ctaText,
  disabled,
  featureAvailable,
  featureEnabled,
  notAvailableMessage,
  notEnabledMessage,
  onClick,
  size,
  theme,
  loading,
  ...rest
}) => {
  const handleFeatureDisabledClick = e => {
    e.preventDefault()
    Notification('error', __(defaultDisabledFeatureMessage), __(notEnabledMessage))
  }

  return (
    <>
      {featureAvailable ? (
        <button
          className={`Button Button-size-${size} Button-theme-${theme}`}
          type='button'
          disabled={disabled}
          onClick={featureEnabled ? onClick : handleFeatureDisabledClick}
          {...rest}
        >
          <>
            {loading && <ContentLoader />}
            {children}
          </>
        </button>
      ) : (
        <Tooltip
          active={!featureAvailable}
          content={
            <div className='feature-unavailable-tooltip'>
              {__(notAvailableMessage)}
              &nbsp;
              {ctaText ? (
                <>{ctaText}</>
              ) : (
                <>
                  {__('To compare different plan types click')}
                  <a
                    style={{ marginLeft: '3px' }}
                    href='https://licensespring.com/pricing'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {__('here')}
                  </a>
                </>
              )}
            </div>
          }
        >
          <button
            className={`Button Button-size-${size} Button-theme-${theme} Button-feature-unavailable`}
            type='button'
            disabled={disabled}
            onClick={e => e.preventDefault()}
            {...rest}
          >
            <>
              <IconLock className='Button-disabled-icon' />
              {children}
            </>
          </button>
        </Tooltip>
      )}
    </>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  theme: PropTypes.oneOf(['default', 'info', 'warning', 'success', 'error', 'link']),
  featureAvailable: PropTypes.bool,
  featureEnabled: PropTypes.bool,
  notAvailableMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  notEnabledMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

Button.defaultProps = {
  ctaText: '',
  disabled: false,
  loading: false,
  onClick: null,
  featureAvailable: true,
  featureEnabled: true,
  notAvailableMessage: 'This feature is not available in your plan type.',
  notEnabledMessage: '',
  size: 'md',
  theme: 'default'
}

export default Button
