import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import moment from 'moment'
import { Button, DescriptionTable, Notice } from 'shared/components'
import {
  checkTrial,
  displayValue,
  formatDate,
  capitalizeFirstLetter,
  formatAmountValue,
  mapCurrencySymbol,
  displayCouponName,
  displayTaxRate,
  getSelectedPricingPlan,
  getUpcomingInvoiceDate
} from 'shared/helpers'
import { defaultDateFormat } from 'shared/constants'
import ChangeTrialForm from '../ChangeTrialForm'
import StandardSubscriptionForm from '../StandardSubscriptionForm'

const StandardTrial = ({ companyDetails, coupons, standardPricingPlans, taxRates }) => {
  const [isTrialFormDisplayed, setIsTrialFormDisplayed] = useState(false)
  const [isSubscriptionFormDisplayed, setSubscriptionFormDisplayed] = useState(false)

  const trial = checkTrial()
  const isTrialExpired = get(trial, 'trial_expired')
  const couponName = displayCouponName(
    get(companyDetails, 'upcoming_invoice.discounts[0].coupon.id') ||
      get(companyDetails, 'payment_coupon_code'),
    coupons
  )
  const taxRate = displayTaxRate(get(companyDetails, 'stripe_tax_id'), taxRates)
  const taxAmount = formatAmountValue(get(companyDetails, 'upcoming_invoice.tax'))
  const pricingPlan = getSelectedPricingPlan(
    get(companyDetails, 'upcoming_invoice.plan_id'),
    standardPricingPlans
  )
  const nextPaymentDate = getUpcomingInvoiceDate(companyDetails)

  return (
    <div className='StandardTrial'>
      <div className='section-header'>{__('Trial')}</div>
      <div className='section-body'>
        <div>
          {isTrialExpired && (
            <Notice theme='error' size='sm'>
              {__('Trial period has expired.')}
            </Notice>
          )}
          <div className='list-header'>
            <div>
              <Button size='sm' theme='info' onClick={() => setIsTrialFormDisplayed(true)}>
                {isTrialExpired ? __('Add new trial period') : __('Change trial days')}
              </Button>
              <Button size='sm' theme='default' onClick={() => setSubscriptionFormDisplayed(true)}>
                {__('Edit billing data')}
              </Button>
            </div>
          </div>
          <DescriptionTable
            details={[
              {
                label: __('Is trial active'),
                value: get(trial, 'isTrial') ? __('Yes') : __('No')
              },
              {
                label: __('Trial start'),
                value: formatDate(get(companyDetails, 'trial_start'), defaultDateFormat)
              },
              {
                label: __('Trial days'),
                value: displayValue(get(companyDetails, 'trial_days'))
              },
              {
                label: __('Trial end date'),
                value: moment(trial.trialEnd).format(defaultDateFormat)
              },
              {
                label: __('Remaining trial days'),
                value: isTrialExpired ? 0 : displayValue(get(trial, 'remaining_days'))
              },
              {
                label: __('Pricing plan'),
                value: get(pricingPlan, 'nickname') || '-'
              },
              {
                label: __('Billing cycle'),
                value: capitalizeFirstLetter(get(pricingPlan, 'interval')) || '-'
              },
              {
                label: __('Amount'),
                value: formatAmountValue(get(pricingPlan, 'amount'))
              },
              {
                label: __('Currency'),
                value: mapCurrencySymbol(get(pricingPlan, 'currency')) || '-'
              },
              { label: __('Discount'), value: couponName },
              { label: __('Tax rate'), value: taxRate },
              { label: __('Tax amount'), value: taxAmount },
              {
                label: __('Total'),
                value: `${formatAmountValue(
                  get(companyDetails, 'upcoming_invoice.total')
                )} ${mapCurrencySymbol(get(pricingPlan, 'currency')) || ''}`
              },
              {
                label: nextPaymentDate ? __('Next billing date') : null,
                value: moment(nextPaymentDate).format('YYYY-MM-DD')
              }
            ]}
          />
        </div>
      </div>
      {isTrialFormDisplayed && (
        <ChangeTrialForm
          companyId={get(companyDetails, 'id')}
          extend={isTrialExpired}
          trialDays={get(companyDetails, 'trial_days')}
          passedTrialDays={get(trial, 'passed_trial_days')}
          trialStart={get(companyDetails, 'trial_start')}
          closeCb={() => setIsTrialFormDisplayed(false)}
          title={isTrialExpired ? __('Add new trial period') : __('Change trial days')}
        />
      )}
      {isSubscriptionFormDisplayed && (
        <StandardSubscriptionForm
          closeCb={() => setSubscriptionFormDisplayed(false)}
          companyDetails={companyDetails}
          title={__('Edit billing data')}
          coupons={coupons}
          taxRates={taxRates}
          pricingPlans={standardPricingPlans}
          hasSubscription={false}
        />
      )}
    </div>
  )
}

StandardTrial.propTypes = {
  companyDetails: PropTypes.object.isRequired,
  coupons: PropTypes.array.isRequired,
  standardPricingPlans: PropTypes.array.isRequired,
  taxRates: PropTypes.array.isRequired
}

export default StandardTrial
