import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import {
  sendErrorReport,
  parseMetadata,
  isFeatureEnabled,
  isFeatureAvailable,
  getDisabledMessage
} from 'shared/helpers'
import {
  productFeatureTypes,
  productFeatureCodeMaxLength,
  errorMsg,
  platformFeatures
} from 'shared/constants'
import {
  Checkbox,
  DirtyFormAlert,
  Label,
  InputErrorMessage,
  Modal,
  Notification,
  NumberInput,
  ProductFeaturesTypeSelector,
  Selector,
  TextInput,
  TextArea
} from 'shared/components'
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
  validateRequiredNumber,
  debouncedValidateRequiredNumber,
  validateJSON,
  debouncedValidateJSON
} from 'shared/validation'
import { postFeature, patchFeature } from 'src/product/actions'
import './styles.scss'

const ProductFeatureForm = ({
  availableFeatures,
  closeModal,
  feature,
  features,
  fetchProduct,
  productId,
  companyID,
  updateProducts
}) => {
  // todo_metadata replace this check with company feature
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const isEnterprisePlan = get(companyDetails, 'plan_type') === 'enterprise'

  const title = feature
    ? `${__('Edit product feature -')} ${get(feature, 'name')}`
    : __('Add product feature')
  const consumptionPeriodOptions = [
    { label: __('Daily'), value: 'daily' },
    { label: __('Weekly'), value: 'weekly' },
    { label: __('Monthly'), value: 'monthly' },
    { label: __('Annually'), value: 'annually' }
  ]

  const [isLoading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)
  const [code, setCode] = useState(get(feature, 'code') || '')
  const [codeError, setCodeError] = useState('')
  const [name, setName] = useState(get(feature, 'name') || '')
  const [nameError, setNameError] = useState('')
  const [type, setType] = useState(get(feature, 'feature_type') || '')
  const [typeError, setTypeError] = useState('')
  const [consumption, setConsumption] = useState(get(feature, 'max_consumption') || 1)
  const [consumptionError, setConsumptionError] = useState('')
  const [allowUnlimitedConsumptions, setAllowUnlimitedConsumptions] = useState(
    get(feature, 'allow_unlimited_consumptions') || false
  )
  const [allowNegativeConsumptions, setAllowNegativeConsumptions] = useState(
    get(feature, 'allow_negative_consumptions') || false
  )
  const [allowOverages, setAllowOverages] = useState(get(feature, 'allow_overages'))
  const [maxOverages, setMaxOverages] = useState(get(feature, 'max_overages') || 0)
  const [maxOveragesError, setMaxOveragesError] = useState('')
  const [resetConsumption, setResetConsumption] = useState(get(feature, 'reset_consumption'))
  const [resetConsumptionPeriod, setResetConsumptionPeriod] = useState(
    get(feature, 'consumption_period') || 'daily'
  )
  // metadata
  const [metadata, setMetadata] = useState(parseMetadata(get(feature, 'metadata')))
  const [metadataError, setMetadataError] = useState('')
  // floating
  const [isFloating, setIsFloating] = useState(get(feature, 'is_floating') || false)
  const [isFloatingCloud, setIsFloatingCloud] = useState(get(feature, 'is_floating_cloud') || false)
  const [floatingUsers, setFloatingUsers] = useState(get(feature, 'floating_users') || 1)
  const [floatingUsersError, setFloatingUsersError] = useState('')
  const [floatingTimeout, setFloatingTimeout] = useState(get(feature, 'floating_timeout') || 120)
  const [floatingTimeoutError, setFloatingTimeoutError] = useState('')

  const notAvailableFloatingMessage = (
    <>
      {__('Floating features are unavailable in your current plan.')}
      &nbsp;
      {__('Upgrade to use floating features.')}
    </>
  )

  const validateValue = async (val, cb, maxLength = undefined) => {
    let errors
    try {
      errors = await validateRequiredValue(val, maxLength)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate feature form value', {
        value: val
      })
    }
    if (errors) {
      return false
    }
    return true
  }

  const validateMetadata = async val => {
    setLoading(true)
    let errors
    try {
      errors = await validateJSON(metadata)
      setMetadataError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit feature form value', {
        value: val
      })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const validateFeatureType = () => {
    if (type) {
      return true
    }
    setTypeError(errorMsg.required)
    return false
  }

  const validateNumber = async (val, cb) => {
    if (type === productFeatureTypes.activation) {
      return true
    }

    let errors
    try {
      errors = await validateRequiredNumber(val)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate feature form value', {
        value: val
      })
    }
    if (errors) {
      return false
    }
    return true
  }

  const validateUniqueCode = () => {
    if (!code) {
      return false
    }

    const openedFeatureCode = get(feature, 'code') || ''
    const codeToTest = code.toLowerCase()
    const doesExist = features
      .filter(f => f.code !== openedFeatureCode)
      .find(f => f.code === codeToTest)

    if (doesExist) {
      setCodeError(`${__('This code is already used for feature')}: ${get(doesExist, 'name')}`)
      return false
    }

    return true
  }

  const handleAllowOveragesChange = val => {
    setDirty(true)
    setAllowOverages(val)
    setMaxOverages(get(feature, 'max_overages') || 0)
    setMaxOveragesError('')
  }

  const handleMaxOveragesChange = val => {
    setDirty(true)
    setMaxOverages(val)
    debouncedValidateRequiredValue(val).then(err => setMaxOveragesError(err))
  }

  const handleResetConsumptionChange = val => {
    setDirty(true)
    setResetConsumption(val)
  }

  const handleResetConsumptionPeriodChange = val => {
    setDirty(true)
    setResetConsumptionPeriod(val)
  }

  const handleAllowUnlimitedConsumptions = val => {
    setDirty(true)
    setAllowUnlimitedConsumptions(val)
    setConsumption(get(feature, 'max_consumption') || 1)
    setConsumptionError('')
  }

  const isFormValid = async () => {
    const isConsumptionType = type === 'consumption'

    const isCodeValid = await validateValue(code, setCodeError, productFeatureCodeMaxLength)
    const isNameValid = await validateValue(name, setNameError)
    const isTypeValid = validateFeatureType()
    const isMaxConsumptionValid = isConsumptionType
      ? await validateNumber(consumption, setConsumptionError)
      : true
    const isMaxOveragesValid =
      allowOverages && isConsumptionType
        ? await validateNumber(maxOverages, setMaxOveragesError)
        : true
    const isCodeUnique = validateUniqueCode()
    const isMetadataValid = await validateMetadata(metadata, setMetadataError)
    return (
      isCodeValid &&
      isNameValid &&
      isTypeValid &&
      isCodeUnique &&
      isMaxConsumptionValid &&
      isMaxOveragesValid &&
      isMetadataValid
    )
  }

  const updateFeature = (data, featureID) => {
    setLoading(true)
    patchFeature(data, featureID, companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'), __('Product feature updated'))
        fetchProduct()
        updateProducts()
        closeModal()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit product feature', data)
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const addFeature = data => {
    setLoading(true)
    postFeature(data, companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'), __('Product feature added'))
        fetchProduct()
        updateProducts()
        closeModal()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add product feature', data)
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const getFloatingUsers = () => {
    if (!isFloating && !isFloatingCloud) {
      return undefined
    }
    if (floatingUsers) {
      return Number(floatingUsers)
    }
    return undefined
  }

  const getFloatingTimeout = () => {
    if (!isFloating && !isFloatingCloud) {
      return undefined
    }
    if (floatingTimeout) {
      return Number(floatingTimeout)
    }
    return undefined
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const isValid = await isFormValid()
    if (!isValid || isLoading) {
      return false
    }

    const floatingUsersNumber = getFloatingUsers()
    const floatingTimeoutValue = getFloatingTimeout()

    const data = {
      name,
      code,
      feature_type: type,
      max_consumption: type === productFeatureTypes.consumption ? Number(consumption) : undefined,
      allow_unlimited_consumptions:
        type === productFeatureTypes.consumption ? allowUnlimitedConsumptions : undefined,
      allow_negative_consumptions:
        type === productFeatureTypes.consumption ? allowNegativeConsumptions : undefined,
      allow_overages: type === productFeatureTypes.consumption ? allowOverages : undefined,
      max_overages: type === productFeatureTypes.consumption ? Number(maxOverages) : undefined,
      reset_consumption: type === productFeatureTypes.consumption ? resetConsumption : undefined,
      consumption_period:
        type === productFeatureTypes.consumption ? resetConsumptionPeriod : undefined,
      product: productId,
      metadata: metadata ? JSON.parse(metadata) : {},
      is_floating: isFloating,
      is_floating_cloud: isFloatingCloud,
      floating_users: floatingUsersNumber,
      floating_timeout: floatingTimeoutValue || 120
    }

    if (feature) {
      updateFeature(data, get(feature, 'id'))
    } else {
      addFeature(data)
    }
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeModal()
    }
    return setDirtyFormAlertDisplay(true)
  }

  return (
    <Modal confirmCb={handleSubmit} closeCb={handleClose} disabled={isLoading} title={title}>
      <div className='ProductFeatureForm'>
        <form className='product-feature-form' onSubmit={handleSubmit}>
          <div className='form-inner'>
            <div className='left'>
              <div className='form-row'>
                <Label text={__('Name')} inputId='name' />
                <TextInput
                  id='name'
                  value={name}
                  error={nameError}
                  handleChange={val => {
                    setDirty(true)
                    setName(val)
                    debouncedValidateRequiredValue(val).then(err => setNameError(err))
                  }}
                />
              </div>
              <div className='form-row'>
                <Label text={__('Code')} inputId='code' />
                <TextInput
                  id='code'
                  value={code}
                  error={codeError}
                  handleChange={val => {
                    setDirty(true)
                    setCode(val)
                    debouncedValidateRequiredValue(val, productFeatureCodeMaxLength).then(err =>
                      setCodeError(err)
                    )
                  }}
                  disabled={!!feature}
                />
              </div>
            </div>
            <div className='divider active' />
            <div className='right'>
              <div className='form-row'>
                <Label text={__('Feature type')} inputId='type' />
                <ProductFeaturesTypeSelector
                  selectedFeature={type}
                  handleFeatureChange={val => {
                    setDirty(true)
                    setType(val)
                    setTypeError('')
                  }}
                  availableFeatures={availableFeatures}
                />
                <InputErrorMessage text={typeError} />
              </div>
              {type === productFeatureTypes.consumption && (
                <>
                  <div className='form-row initial-checkbox'>
                    <Label text={__('Max consumption')} inputId='consumption' />
                    <NumberInput
                      value={consumption}
                      error={consumptionError}
                      min='1'
                      max='2147483647'
                      handleChange={val => {
                        setDirty(true)
                        setConsumption(val)
                        debouncedValidateRequiredNumber(val).then(err => setConsumptionError(err))
                      }}
                      disabled={allowUnlimitedConsumptions}
                    />
                    <Checkbox
                      label={__('Allow unlimited consumptions')}
                      inputId='allow-unlimited-consumptions-checkbox'
                      checked={allowUnlimitedConsumptions}
                      handleChange={handleAllowUnlimitedConsumptions}
                    />
                  </div>
                  <div className='form-row'>
                    <Checkbox
                      label={__('Allow negative consumptions')}
                      inputId='allow-negative-consumptions-checkbox'
                      checked={allowNegativeConsumptions}
                      handleChange={val => setAllowNegativeConsumptions(val)}
                    />
                  </div>
                  <div className='form-row'>
                    <Checkbox
                      label={__('Allow overages')}
                      inputId='consumption-overages'
                      checked={allowOverages}
                      handleChange={handleAllowOveragesChange}
                    />
                  </div>
                  {allowOverages && (
                    <div className='form-row row-expanded expanded-consumption-row'>
                      <Label text={__('Max overages')} inputId='max-overages-input' />
                      <NumberInput
                        handleChange={handleMaxOveragesChange}
                        value={maxOverages}
                        showErrorMsg={false}
                        error={maxOveragesError}
                        min='0'
                        max='2147483647'
                        id='max-overages-input'
                      />
                      <InputErrorMessage text={maxOveragesError} />
                    </div>
                  )}
                  <div className='form-row'>
                    <Checkbox
                      label={__('Reset consumption')}
                      inputId='consumption-reset'
                      checked={resetConsumption}
                      handleChange={handleResetConsumptionChange}
                    />
                  </div>
                  {resetConsumption && (
                    <div className='form-row row-expanded'>
                      <Label text={__('Consumption period')} />
                      <Selector
                        options={consumptionPeriodOptions}
                        value={resetConsumptionPeriod}
                        handleChange={handleResetConsumptionPeriodChange}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className='row'>
            <Label inputId='metadata-input' text={__('Metadata JSON')} />
            <TextArea
              handleChange={val => {
                setDirty(true)
                setMetadata(val)
                debouncedValidateJSON(val).then(err => setMetadataError(err))
              }}
              id='metadata-input'
              type='metadata'
              value={metadata}
              error={metadataError}
              rows='4'
              disabled={!isEnterprisePlan}
            />
          </div>
          <div className='row'>
            <div className='section-row'>
              <Checkbox
                label={__('Offline floating feature')}
                inputId='floating-feature'
                checked={isFloating}
                handleChange={val => {
                  setDirty(true)
                  setIsFloating(val)
                  setIsFloatingCloud(false)
                  setFloatingUsers(get(feature, 'floating_users') || 1)
                  setFloatingUsersError('')
                }}
                featureEnabled={isFeatureEnabled(platformFeatures.model_floating_cloud)}
                notEnabledMessage={getDisabledMessage()}
                featureAvailable={isFeatureAvailable(platformFeatures.model_floating_cloud)}
                notAvailableMessage={notAvailableFloatingMessage}
              />
            </div>
            <div className='section-row'>
              <Checkbox
                label={__('Is floating cloud')}
                inputId='floating-cloud-feature'
                checked={isFloatingCloud}
                handleChange={val => {
                  setDirty(true)
                  setIsFloatingCloud(val)
                  setIsFloating(false)
                  setFloatingUsers(get(feature, 'floating_users') || 1)
                  setFloatingUsersError('')
                  // if (!val) {
                  //   setUnlimitedMaxLicenseUsers(false);
                  // }
                  // if (val && maxLicenseUsers === 0) {
                  //   setMaxLicenseUsersError('');
                  //   setUnlimitedMaxLicenseUsers(true);
                  // }
                }}
                featureEnabled={isFeatureEnabled(platformFeatures.model_floating_cloud)}
                notEnabledMessage={getDisabledMessage()}
                featureAvailable={isFeatureAvailable(platformFeatures.model_floating_cloud)}
                notAvailableMessage={notAvailableFloatingMessage}
              />
            </div>
            {(isFloating || isFloatingCloud) && (
              <div className='floating-form-fields'>
                <div className='section-row max-simult-users'>
                  <Label text={__('Max simultaneous license users')} />
                  <NumberInput
                    handleChange={val => {
                      setDirty(true)
                      setFloatingUsers(val)
                      // debouncedValidateFloatingUsers(val, maxActivations, allowUnlimitedActivations, isFloatingCloud).then(err => setFloatingUsersError(err));
                    }}
                    value={floatingUsers}
                    error={floatingUsersError}
                    min='1'
                    max='10000'
                  />
                </div>
                <div className='section-row max-simult-users'>
                  <Label
                    text={__('Floating timeout')}
                    description={__(
                      'If the feature issued is a floating feature, the floating timeout is the time interval in minutes that the end users application will need to perform a license check in order to remain registered to the feature.'
                    )}
                  />
                  <NumberInput
                    handleChange={val => {
                      setDirty(true)
                      setFloatingTimeout(val)
                      debouncedValidateRequiredNumber(val).then(err => setFloatingTimeoutError(err))
                    }}
                    value={floatingTimeout}
                    error={floatingTimeoutError}
                    min='1'
                    max='2147483647'
                  />
                </div>
                {/* <Checkbox
                label={__('Can borrow license')}
                inputId="can-borrow-license"
                checked={canBorrow}
                handleChange={handleCanBorrowChange}
                featureEnabled={isFeatureEnabled(platformFeatures.extra_license_borrowing)}
                notEnabledMessage={getDisabledMessage()}
                featureAvailable={isFeatureAvailable(platformFeatures.extra_license_borrowing)}
                notAvailableMessage={notAvailableFloatingMessage}
              />
              {canBorrow && (
              <div className="section-row row-expanded">
                <Label text={__('Max borrow time')} description={__('Hours')} />
                <NumberInput
                  handleChange={handleMaxBorrowTimeChange}
                  value={maxBorrowTime}
                  error={maxBorrowTimeError}
                  min="1"
                  max="2147483647"
                />
              </div>
              )} */}
              </div>
            )}
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={closeModal}
          />
        )}
      </div>
    </Modal>
  )
}

ProductFeatureForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  availableFeatures: PropTypes.object.isRequired,
  feature: PropTypes.object,
  features: PropTypes.array,
  fetchProduct: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  companyID: PropTypes.number.isRequired,
  updateProducts: PropTypes.func.isRequired
}

ProductFeatureForm.defaultProps = {
  feature: undefined,
  features: []
}

export default ProductFeatureForm
