import React, { useState } from 'react'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, IconCheckmark, IconDisabled, IconSearch, List, TextInput } from 'shared/components'
import {
  displayValue,
  filterCompanies,
  formatDate,
  formatAmountValue,
  capitalizeFirstLetter,
  mapCurrencySymbol,
  getUpcomingInvoiceDate,
  isStaffUser
} from 'shared/helpers'
import { defaultDateFormat } from 'shared/constants'
import NewCompanyForm from '../NewCompanyForm'
import GenerateReportForm from '../GenerateReportForm'

const ActiveCompaniesList = () => {
  const history = useHistory()
  const companies = useSelector(state => get(state, 'account.companies') || [])

  const [query, setQuery] = useState('')
  const [showForm, setFormDisplay] = useState(false)
  const [isReportFormDisplayed, setReportFormDisplay] = useState(false)

  const filteredCompanies = filterCompanies(companies, query)
  const companiesNum = companies.length

  const isStaff = isStaffUser()

  return (
    <div className='ActiveCompaniesList'>
      <div className='list-header'>
        <div>
          <form onSubmit={e => e.preventDefault()}>
            <TextInput
              handleChange={val => setQuery(val)}
              value={query}
              placeholder={__('Search companies')}
            />
            {query && (
              <button type='button' onClick={() => setQuery('')}>
                &times;
              </button>
            )}
            <Button type='submit' theme='info'>
              <IconSearch fill='#fff' />
            </Button>
          </form>
        </div>
        {isStaff && (
          <div>
            <Button onClick={() => setReportFormDisplay(true)} theme='default'>
              {__('Generate report')}
            </Button>
            <Button onClick={() => setFormDisplay(!showForm)} theme='info'>
              {__('New company')}
            </Button>
          </div>
        )}
      </div>
      <List
        clickable
        data={filteredCompanies}
        minRows={companiesNum > 10 ? 10 : 1}
        showPageJump={false}
        defaultPageSize={10}
        handleClick={rowData => history.push(`/${rowData.original.id}`)}
        columns={[
          {
            accessor: 'name',
            Header: __('Company name'),
            Cell: cellInfo => displayValue(cellInfo.value)
          },
          {
            accessor: 'plan_type',
            Header: __('Plan type'),
            Cell: cellInfo => displayValue(cellInfo.value),
            maxWidth: 100
          },
          {
            accessor: 'has_payment_method',
            Header: __('Payment method'),
            Cell: cellData => {
              const isBankTransfer = get(cellData, 'original.is_payment_manual')
              if (isBankTransfer) {
                return __('Bank transfer')
              }
              return cellData.value ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='#aaa' height='14px' />
              )
            },
            show: isStaff
          },
          {
            accessor: 'subscription_cycle',
            Header: __('Billing cycle'),
            Cell: cellInfo => displayValue(capitalizeFirstLetter(cellInfo.value)),
            show: isStaff
          },
          {
            accessor: 'upcoming_invoice.next_payment_attempt',
            Header: __('Next invoice date'),
            Cell: cellData => {
              const date = getUpcomingInvoiceDate(get(cellData, 'original'))
              if (!date) {
                return '-'
              }
              return formatDate(date, defaultDateFormat)
            },
            show: isStaff
          },
          {
            accessor: 'upcoming_invoice.total',
            Header: __('Amount'),
            Cell: cellData => {
              const currency = get(cellData, 'original.upcoming_invoice.currency')
              if (!cellData.value) {
                return '-'
              }
              return `${formatAmountValue(cellData.value)} ${mapCurrencySymbol(currency)}`
            },
            maxWidth: 100,
            show: isStaff
          },
          {
            accessor: 'last_login',
            Header: __('Last login'),
            Cell: cellInfo => formatDate(cellInfo.value),
            show: isStaff
          },
          {
            accessor: 'product_count',
            Header: __('Products'),
            Cell: cellInfo => displayValue(cellInfo.value),
            maxWidth: 100
          },
          {
            accessor: 'license_count',
            Header: __('Licenses'),
            Cell: cellInfo => displayValue(cellInfo.value),
            maxWidth: 100
          },
          {
            accessor: 'last_license',
            Header: __('Last license created'),
            Cell: cellInfo => formatDate(cellInfo.value)
          }
        ]}
      />
      {showForm && (
        <NewCompanyForm
          closeCb={() => setFormDisplay(false)}
          history={history}
          companies={companies}
        />
      )}
      {isReportFormDisplayed && <GenerateReportForm closeCb={() => setReportFormDisplay(false)} />}
    </div>
  )
}

export default ActiveCompaniesList
