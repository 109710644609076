import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'
import TagManager from 'react-gtm-module'
import { useSelector } from 'react-redux'
import { Button, Checkbox, Notification } from 'shared/components'
import { sendErrorReport } from 'shared/helpers'
import { updateUserData } from 'src/user/actions'
import './styles.scss'

const ConsentForm = ({ refetchUser, userId }) => {
  const usersCompanies = useSelector(state => get(state, 'account.companies') || [])
  const companyDetails = get(usersCompanies, '[0]')
  const companyID = get(companyDetails, 'id')
  const planType = get(companyDetails, 'plan_type')
  const isLinked = get(companyDetails, 'main_company')
  const usersCount = get(companyDetails, 'user_count')

  const [loading, setLoading] = useState(false)
  const [consentTerms, setConsentTerms] = useState(false)
  const [consentPrivacy, setConsentPrivacy] = useState(false)
  const [subscription, setSubscription] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    const userData = {
      subscribed_to_email: subscription,
      acquired_consent: moment().format('YYYY-MM-DD')
    }

    if (process.env.REACT_APP_GTM_ID) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'new_account_creation',
          account_number: companyID,
          account_tier: planType,
          account_type: isLinked ? 'linked' : 'main',
          user_num: usersCount
        }
      })
    }

    updateUserData(userId, userData, companyID)
      .then(res => {
        setLoading(false)
        refetchUser(res.data)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot submit consent data', userData)
        setLoading(false)
        Notification('error', __('Error occured'), __('Your changes were not saved'))
      })
  }

  return (
    <div className='ConsentForm'>
      <div className='consent-form-wrapper'>
        <form className='consent-form' id='consent-form' onSubmit={handleSubmit}>
          <div className='row logo-cont'>
            <img src='/img/ls-logo.svg' alt='logo' height='120px' />
          </div>
          <div className='row title-cont'>
            <h1>{__('Welcome to LicenseSpring')}</h1>
          </div>
          <div className='row subtitle-cont'>
            <p>{__('Before you continue...')}</p>
          </div>
          <div className='row input-cont'>
            <Checkbox
              inputId='consent-terms-checkbox'
              required
              handleChange={() => setConsentTerms(!consentTerms)}
              checked={consentTerms}
            />
            <p>
              {__('By clicking this box you agree to the LicenseSpring')}
              <a
                href='https://licensespring.com/terms-of-service'
                target='_blank'
                rel='noopener noreferrer'
              >
                {__('Terms and Conditions')}
              </a>
              {__('that incorporates also the LicenseSpring')}
              <a
                href='https://licensespring.com/service-level-agreement'
                target='_blank'
                rel='noopener noreferrer'
              >
                {__('Service Level Agreement')}
              </a>
              {__('and the')}
              <a
                href='https://licensespring.com/data-processing'
                target='_blank'
                rel='noopener noreferrer'
              >
                {__('Data Processing Addendum')}
              </a>
              {`(${__('if applicable as per Sect. 5.3 of LicenseSpring Terms and Conditions')}).`}
            </p>
          </div>
          <div className='row input-cont'>
            <Checkbox
              inputId='consent-privacy-checkbox'
              required
              handleChange={() => setConsentPrivacy(!consentPrivacy)}
              checked={consentPrivacy}
            />
            <p>
              {__('By clicking this box you declare to have read and to accept the LicenseSpring')}
              <a href='https://licensespring.com/privacy' target='_blank' rel='noopener noreferrer'>
                {__('Privacy Policy')}
              </a>
              {`, ${__(
                'authorizing LicenseSpring to process your personal data for the purposes therein specified and connected with the provision of the services you requested to LicenseSpring'
              )}.`}
            </p>
          </div>
          <div className='row input-cont'>
            <Checkbox
              inputId='consent-subscription-checkbox'
              handleChange={() => setSubscription(!subscription)}
              checked={subscription}
            />
            <p>
              {__(
                'By clicking this box you authorize LicenseSpring to process your personal data at the purpose of sending you the LicenseSpring newsletter and for marketing purposes mentioned in the LicenseSpring'
              )}
              <a href='https://licensespring.com/privacy' target='_blank' rel='noopener noreferrer'>
                {__('Privacy Policy')}
              </a>
              {`. ${'Remember that you can always unsubscribe the emails LicenseSpring sends you. (OPTIONAL)'}`}
            </p>
          </div>
          <div className='row btn-cont'>
            <Button
              size='lg'
              theme='success'
              // type="submit"
              onClick={handleSubmit}
              disabled={loading}
              id='consent-form-submit'
            >
              {__('Continue')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

ConsentForm.propTypes = {
  refetchUser: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired
}

export default ConsentForm
