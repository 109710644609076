import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { Notification } from 'shared/components'
import { sendErrorReport } from 'shared/helpers'

export const useOAuth = ({
  entityId,
  companyID,
  getClient,
  createClient,
  deleteClient,
  rotateSecret,
  refreshSecret
}) => {
  const [isLoading, setLoading] = useState(true)
  const [loadingCreateClient, setLoadingCreateClient] = useState(false)
  const [oAuthClient, setOAuthClient] = useState(null)
  const [isRotateSecretModalOpen, setRotateSecretModalOpen] = useState(false)
  const [isRotateConfirmationOpen, setRotateConfirmationOpen] = useState(false)
  const [isRefreshConfirmationOpen, setRefreshConfirmationOpen] = useState(false)
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const defaultRotationSettings = get(companyDetails, 'oauth_clients_data')
  const [rotateSecretData, setRotateSecretData] = useState({
    secret_expiration_period: get(
      defaultRotationSettings,
      'secret_expiration_period',
      60 * 60 * 24 * 30
    ),
    secret_rotated_expiration_period: get(
      defaultRotationSettings,
      'secret_rotated_expiration_period',
      60 * 60 * 24 * 7
    ),
    secret_remaining_rotation_period: 60 * 60 * 24 * 2
  })

  const getOauthClient = useCallback(() => {
    getClient(entityId, companyID)
      .then(res => {
        setLoading(false)
        setOAuthClient(get(res, 'data'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get oauth client')
        setLoading(false)
      })
  }, [entityId, companyID, getClient])

  useEffect(() => {
    getOauthClient()
  }, [getOauthClient])

  const handleRefreshSecret = () => {
    setLoading(true)
    refreshSecret(entityId, companyID)
      .then(() => {
        getOauthClient()
        setRefreshConfirmationOpen(false)
        setLoading(false)
        Notification('success', __('Client secret refreshed successfully'))
      })
      .catch(err => {
        setLoading(false)
        sendErrorReport(err, 'Cannot refresh oauth client secret')
        Notification(
          'error',
          __('There was an error while refreshing client secret'),
          __('Try again later')
        )
      })
  }

  const createNewOauthClient = () => {
    setLoadingCreateClient(true)
    createClient(entityId, companyID)
      .then(() => {
        getOauthClient()
        setLoadingCreateClient(false)
        Notification('success', __('New OAuth client created'))
      })
      .catch(err => {
        setLoadingCreateClient(false)
        sendErrorReport(err, 'Cannot create new oauth client')
        Notification(
          'error',
          __('There was an error while creating new OAuth client'),
          __('Try again later')
        )
      })
  }

  const handleDeleteOauthClient = () => {
    setLoading(true)
    deleteClient(entityId, companyID)
      .then(() => {
        setOAuthClient(null)
        setLoading(false)
        setDeleteConfirmationOpen(false)
        Notification('success', __('OAuth client deleted successfully'))
      })
      .catch(err => {
        setLoading(false)
        sendErrorReport(err, 'Cannot delete oauth client')
        Notification(
          'error',
          __('There was an error while deleting OAuth client'),
          __('Try again later')
        )
      })
  }

  const handleRotateSecret = () => {
    setLoading(true)

    if (companyID) {
      rotateSecret(entityId, companyID, rotateSecretData)
        .then(() => {
          getOauthClient()
          setRotateConfirmationOpen(false)
          setLoading(false)
          Notification('success', __('Client secret rotated successfully'))
        })
        .catch(err => {
          setLoading(false)
          sendErrorReport(err, 'Cannot rotate oauth client secret')
          Notification(
            'error',
            __('There was an error while rotating client secret'),
            __('Try again later')
          )
        })
    } else {
      rotateSecret(entityId, rotateSecretData)
        .then(() => {
          getOauthClient()
          setRotateConfirmationOpen(false)
          setLoading(false)
          Notification('success', __('Client secret rotated successfully'))
        })
        .catch(err => {
          setLoading(false)
          sendErrorReport(err, 'Cannot rotate oauth client secret')
          Notification(
            'error',
            __('There was an error while rotating client secret'),
            __('Try again later')
          )
        })
    }
  }

  return {
    isLoading,
    loadingCreateClient,
    oAuthClient,
    isRotateSecretModalOpen,
    setRotateSecretModalOpen,
    isRotateConfirmationOpen,
    setRotateConfirmationOpen,
    isRefreshConfirmationOpen,
    setRefreshConfirmationOpen,
    isDeleteConfirmationOpen,
    setDeleteConfirmationOpen,
    rotateSecretData,
    setRotateSecretData,
    getOauthClient,
    createNewOauthClient,
    handleDeleteOauthClient,
    handleRotateSecret,
    handleRefreshSecret
  }
}

export default useOAuth
