import React, { useState, useReducer } from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { FormStepper, FormStepperItem, Notification, NotFound, Page } from 'shared/components'
import {
  isFeatureAvailable,
  isFeatureEnabled,
  getProductNumber,
  sendErrorReport
} from 'shared/helpers'
import { platformFeatures } from 'shared/constants'
import { getProducts, createProduct } from 'src/product/actions'
import { initialState } from './constants'
import { newProductReducer, setProductInformations } from './helpers'
import { ProductInformations, VerifyProduct } from './components'
import './styles.scss'

const CreateProductForm = () => {
  const history = useHistory()
  const dispatchAction = useDispatch()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const companyPlan = useSelector(state => get(state, 'company.details.plan_type'))

  const [formState, dispatch] = useReducer(newProductReducer, initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [isDirty, setDirty] = useState(false)
  const [activeStep, setActiveStep] = useState(1)

  const handleBack = () => setActiveStep(activeStep - 1)

  const handleProductInformationsSubmit = data => {
    dispatch(setProductInformations(data))
    setActiveStep(2)
  }

  const handleProductCreate = () => {
    setIsLoading(true)
    setDirty(false)
    createProduct(formState, companyID)
      .then(res => {
        dispatchAction(getProducts(companyID))
        Notification(
          'success',
          __('Product created succesfully'),
          `${__('Product name')}: ${get(res, 'data.product_name')}`
        )
        history.push(`/${companyID}/products/${get(res, 'data.id')}/`)
      })
      .catch(err => {
        setIsLoading(false)
        setDirty(true)
        sendErrorReport(err, 'Cannot create new product', formState)
        Notification('error', __('Error occured'))
      })
  }

  const isPageUnavailable = () => {
    const featureEnabled = isFeatureEnabled(platformFeatures.usage_products)
    const featureAvailable = isFeatureAvailable(platformFeatures.usage_products, getProductNumber())
    return !featureEnabled || !featureAvailable
  }

  if (isPageUnavailable()) {
    return (
      <div className='Unavailable-form'>
        <Page>
          <NotFound />
        </Page>
      </div>
    )
  }

  return (
    <Page title={__('Create new product')}>
      <Prompt
        message={__(
          'By leaving this form you will lose all progress. Are you sure you want to leave?'
        )}
        when={isDirty}
      />
      <div className='CreateProductForm'>
        <FormStepper>
          <>
            <FormStepperItem
              title={__('Product information')}
              description={__('Add product details')}
              isActive={activeStep === 1}
              isCompleted={activeStep > 1}
              step={1}
            />
            <FormStepperItem
              title={__('Confirm product')}
              description={__('Verify product details')}
              isActive={activeStep === 2}
              isCompleted={activeStep > 2}
              step={2}
            />
          </>
        </FormStepper>
      </div>
      <div className='CreateProductForm-innner'>
        {activeStep === 1 && (
          <ProductInformations
            companyID={companyID}
            companyPlan={companyPlan}
            formState={formState}
            handleSubmit={handleProductInformationsSubmit}
            setDirty={setDirty}
          />
        )}
        {activeStep === 2 && (
          <VerifyProduct
            formState={formState}
            handleBack={handleBack}
            handleSubmit={handleProductCreate}
            isLoading={isLoading}
            companyID={companyID}
            companyPlan={companyPlan}
          />
        )}
      </div>
    </Page>
  )
}

export default CreateProductForm
