import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Page, Tab, Tabs, TabContent, TabsHeader, Notice } from 'shared/components'
import {
  AnalyticsDatePicker,
  AnalyticsSuccesRate,
  AnalyticsReports,
  AnalyticsTotal,
  MonthlyRateLimitBar
} from '../components'

const APIUsage = () => {
  const location = useLocation()
  const history = useHistory()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const sidebarSize = useSelector(state => get(state, 'account.sidebarSize'))
  const maxWidth = sidebarSize === 'lg' ? '1050px' : '1440px'

  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    const queryParams = get(location, 'search')
    if (!queryParams) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=0') >= 0) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=1') >= 0) {
      setSelectedTab(1)
    }
    if (queryParams.indexOf('st=2') >= 0) {
      setSelectedTab(2)
    }
  }, [location])

  return (
    <Page title={__('Analytics')}>
      <div style={{ maxWidth }} className='APIUsage'>
        <AnalyticsDatePicker title={__('API Usage')} />
        <MonthlyRateLimitBar activeCompany={companyID} />
        <Notice size='sm'>{__('Data is aggregated every 24 hours')}</Notice>
        <Tabs
          defaultFocus
          selectedIndex={selectedTab}
          onSelect={tabIndex => {
            history.push({
              search: `?st=${tabIndex}`
            })
            setSelectedTab(tabIndex)
          }}
          forceRenderTabPanel
        >
          <TabsHeader>
            <Tab>{__('Overview')}</Tab>
            <Tab>{__('Transactions')}</Tab>
            <Tab>{__('Reports')}</Tab>
          </TabsHeader>
          <TabContent>
            <AnalyticsTotal />
          </TabContent>
          <TabContent>
            <AnalyticsSuccesRate />
          </TabContent>
          <TabContent>
            <AnalyticsReports />
          </TabContent>
        </Tabs>
      </div>
    </Page>
  )
}

export default APIUsage
