import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { sendErrorReport } from 'shared/helpers'
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  NumberInput,
  Selector
} from 'shared/components'
import { planTypes } from 'shared/constants'
import { validateRequiredNumber, debouncedValidateRequiredNumber } from 'shared/validation'
import { reactivateCompany } from 'src/company/actions'
import { getUserCompaniesSilent } from 'src/account/actions'

const ReactivateCompanyForm = ({ closeCb, company }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const companyPlanType = get(company, 'plan_type') || 'standard'

  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)
  const [loading, setLoading] = useState(false)
  const [planType, setPlanType] = useState(companyPlanType)
  const [trialDays, setTrialDays] = useState(15)
  const [trialDaysError, setTrialDaysError] = useState('')

  // validation methods
  const validateValue = async (val, cb) => {
    setLoading(true)
    let errors

    try {
      errors = await validateRequiredNumber(val, true)
      setLoading(false)
      cb(errors)
    } catch (err) {
      setLoading(false)
      sendErrorReport(err, 'Cannot validate reactivated company trial days', { value: val })
    }
    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isTrialDaysValid = await validateValue(trialDays, setTrialDaysError)
    return isTrialDaysValid
  }

  const handleSubmit = async () => {
    const isValid = await isFormValid()
    if (loading || !isValid) {
      return false
    }

    setLoading(true)
    const companyID = get(company, 'id')
    const data = {
      plan_type: planType,
      trial_days: trialDays
    }

    return reactivateCompany(companyID, data)
      .then(() => dispatch(getUserCompaniesSilent()))
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        history.push(`/${companyID}`)
      })
      .catch(err => {
        setLoading(false)
        sendErrorReport(err, 'Cannot reactivate company', data)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setDirtyFormAlertDisplay(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={`${__('Reactivate company')} - ${get(company, 'name')}`}
      disabled={loading}
    >
      <div className='NewCompanyForm'>
        <div>
          <Label text={__('Plan type')} inputId='company-plantype-input' />
          <Selector
            getOptionLabel={option => `${option.label} - ${option.pricing}`}
            handleChange={val => {
              setDirty(true)
              setPlanType(val)
            }}
            maxWidth='300px'
            options={planTypes}
            value={planType}
          />
        </div>
        {(planType === 'standard' || planType === 'enterprise') && (
          <div>
            <Label text={__('Trial days')} inputId='trial-days-input' />
            <NumberInput
              id='trial-days-input'
              value={trialDays}
              error={trialDaysError}
              min='1'
              max='10000'
              handleChange={val => {
                setDirty(true)
                setTrialDays(val)
                debouncedValidateRequiredNumber(val, true).then(err => setTrialDaysError(err))
              }}
            />
          </div>
        )}
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

ReactivateCompanyForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired
}

export default ReactivateCompanyForm
