import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { Page, Tab, Tabs, TabContent, TabsHeader } from 'shared/components'
import PlatformAuditLog from './PlatformAuditLog'

const AuditLog = () => {
  const location = useLocation()
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    const queryParams = get(location, 'search')
    if (!queryParams) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=0') >= 0) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=1') >= 0) {
      setSelectedTab(1)
    }
  }, [location])

  return (
    <Page title={__('Audit log')}>
      <Tabs
        defaultFocus
        selectedIndex={selectedTab}
        onSelect={tabIndex => {
          history.push({
            search: `?st=${tabIndex}`
          })
          setSelectedTab(tabIndex)
        }}
      >
        <TabsHeader>
          <Tab>{__('Platform')}</Tab>
        </TabsHeader>
        <TabContent>
          <PlatformAuditLog showDatePicker />
        </TabContent>
      </Tabs>
    </Page>
  )
}

export default AuditLog
