import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'shared/components'
import './styles.scss'

const StandardPlanTypeBox = ({ isCurrent, handleCtaClick }) => (
  <article className={`StandardPlanTypeBox PlanTypeBox ${isCurrent ? 'current' : ''}`}>
    <div className='PlanTypeBox-title'>{__('Standard')}</div>
    <p className='PlanTypeBox-description'>
      {__(
        'For ISVs, Game Developers, and organizations looking to protect their business with standard licensing models'
      )}
    </p>
    <div className='PlanTypeBox-price standard'>99</div>
    <div className='PlanTypeBox-trial'>{__('15 days trial')}</div>
    <div className='PlanTypeBox-btn'>
      <Button onClick={handleCtaClick} theme='success' disabled={isCurrent} size='lg'>
        {__('Upgrade to Standard')}
      </Button>
    </div>
    <ul className='PlanTypeBox-features'>
      <li>
        <span>100,000</span>
        {__('monthly active users')}
      </li>
      <li>
        <span>50</span>
        {__('products')}
      </li>
      <li>
        <span>{__('All')}</span>
        {__('development environments')}
      </li>
      <li className='checkmark'>{__('Node-Locking')}</li>
      <li className='checkmark'>{__('Key or User-Based')}</li>
      <li className='checkmark'>{__('Offline Licensing')}</li>
      <li className='checkmark'>{__('Product Versioning')}</li>
    </ul>
  </article>
)

StandardPlanTypeBox.propTypes = {
  handleCtaClick: PropTypes.func.isRequired,
  isCurrent: PropTypes.bool.isRequired
}

export default StandardPlanTypeBox
