import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import {
  Button,
  Notification,
  PermissionMissingNotificationTitle,
  OAuthCredentials,
  OAuthModals
} from 'shared/components'
import { checkUserPermission } from 'shared/helpers'
import { userPermissions } from 'shared/constants'
import { useOAuth } from 'shared/hooks'
import {
  getOauthCompanyClient,
  createOauthCompanyClient,
  deleteOauthCompanyClient,
  rotateOauthCompanyClientSecret,
  createOauthCompanyClientSecret,
  patchOauthCompanyDefaultData
} from 'src/account/actions'
import { getCompany } from 'src/company/actions'
import './styles.scss'

const CompanyOauth = () => {
  const dispatch = useDispatch()
  const canManageSettings = checkUserPermission(userPermissions.settings_write)
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const companyID = get(companyDetails, 'id')

  // State for default settings modal
  const [isDefaultSettingsModalOpen, setDefaultSettingsModalOpen] = useState(false)

  const handleManageSettingsClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb()
    return true
  }

  const {
    isLoading,
    loadingCreateClient,
    oAuthClient,
    isRotateSecretModalOpen,
    setRotateSecretModalOpen,
    isRotateConfirmationOpen,
    setRotateConfirmationOpen,
    isRefreshConfirmationOpen,
    setRefreshConfirmationOpen,
    isDeleteConfirmationOpen,
    setDeleteConfirmationOpen,
    rotateSecretData,
    setRotateSecretData,
    getOauthClient,
    createNewOauthClient,
    handleDeleteOauthClient,
    handleRefreshSecret,
    handleRotateSecret
  } = useOAuth({
    entityId: companyID,
    getClient: getOauthCompanyClient,
    createClient: createOauthCompanyClient,
    deleteClient: deleteOauthCompanyClient,
    rotateSecret: rotateOauthCompanyClientSecret,
    refreshSecret: createOauthCompanyClientSecret
  })

  // Initialize rotation data with defaults when opening the modal
  const handleRotateClick = () => {
    setRotateSecretModalOpen(true)
  }

  const handleRefreshClick = () => {
    setRefreshConfirmationOpen(true)
  }

  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true)
  }

  const handleDefaultSettingsSave = async () => {
    try {
      await patchOauthCompanyDefaultData(companyID, {
        oauth_clients_data: rotateSecretData
      })
      await dispatch(getCompany(companyID))
      setDefaultSettingsModalOpen(false)
      Notification('success', __('Default rotation settings updated successfully'))
    } catch (error) {
      Notification('error', __('Failed to update default rotation settings'))
    }
  }

  return (
    <div className='OAuth'>
      <div className='row'>
        <h3>{__('Company OAuth Configuration')}</h3>
        {oAuthClient && (
          <Button
            size='sm'
            theme='info'
            onClick={() => handleManageSettingsClick(() => setDefaultSettingsModalOpen(true))}
            disabled={isLoading}
          >
            {__('Set Default Rotation Settings')}
          </Button>
        )}
        {!oAuthClient ? (
          <Button
            size='sm'
            theme='info'
            loading={loadingCreateClient}
            disabled={loadingCreateClient || isLoading}
            onClick={() => handleManageSettingsClick(createNewOauthClient)}
          >
            {__('Initialize OAuth')}
          </Button>
        ) : (
          <OAuthCredentials
            oAuthClient={oAuthClient}
            isLoading={isLoading}
            handleManageSettingsClick={handleManageSettingsClick}
            onRotateClick={handleRotateClick}
            onRefreshClick={handleRefreshClick}
            onDeleteClick={handleDeleteClick}
            setRefreshConfirmationOpen={setRefreshConfirmationOpen}
            setRotateConfirmationOpen={setRotateConfirmationOpen}
            setDeleteConfirmationOpen={setDeleteConfirmationOpen}
          />
        )}
      </div>

      <OAuthModals
        isLoading={isLoading}
        isDeleteConfirmationOpen={isDeleteConfirmationOpen}
        setDeleteConfirmationOpen={setDeleteConfirmationOpen}
        handleDeleteOauthClient={handleDeleteOauthClient}
        isRotateConfirmationOpen={isRotateConfirmationOpen}
        setRotateConfirmationOpen={setRotateConfirmationOpen}
        isRefreshConfirmationOpen={isRefreshConfirmationOpen}
        setRefreshConfirmationOpen={setRefreshConfirmationOpen}
        isRotateSecretModalOpen={isRotateSecretModalOpen}
        setRotateSecretModalOpen={setRotateSecretModalOpen}
        handleRotateSecret={handleRotateSecret}
        handleRefreshSecret={handleRefreshSecret}
        getOauthClient={getOauthClient}
        rotateSecretData={rotateSecretData}
        setRotateSecretData={setRotateSecretData}
      />

      {/* Default Settings Modal */}
      <OAuthModals
        isLoading={isLoading}
        isRotateSecretModalOpen={isDefaultSettingsModalOpen}
        setRotateSecretModalOpen={setDefaultSettingsModalOpen}
        handleRotateSecret={handleDefaultSettingsSave}
        rotateSecretData={rotateSecretData}
        setRotateSecretData={setRotateSecretData}
        modalTitle={__('Set Default Rotation Settings')}
        confirmButtonText={__('Save Defaults')}
        confirmButtonTheme='info'
      />
    </div>
  )
}

export default CompanyOauth
