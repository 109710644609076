import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import {
  sendErrorReport,
  getDefaultLicensePolicy,
  formatPolicies,
  parseMetadata
} from 'shared/helpers'
import {
  Checkbox,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  NumberInput,
  Selector,
  TextArea,
  TextInput
} from 'shared/components'
import { checkCompanyConfigField } from 'shared/companyConfig'
import {
  validateProductName,
  debouncedValidateProductName,
  validateRequiredNumber,
  debouncedValidateRequiredNumber,
  validateJSON,
  debouncedValidateJSON
} from 'shared/validation'
import { getProducts, patchProduct, patchLicensePolicy } from 'src/product/actions'
import './styles.scss'

const EditProductForm = ({ closeForm, companyPlan, refetchProduct, product }) => {
  const dispatch = useDispatch()

  const companyID = useSelector(state => get(state, 'company.details.id'))
  const products = useSelector(state => get(state, 'products.list'))
  const archivedProducts = useSelector(state => get(state, 'products.archived'))
  const currentProductName = get(product, 'product_name')
  const isEnterprise = companyPlan === 'enterprise'
  const defaultPolicy = getDefaultLicensePolicy([], product)

  const [isLoading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)
  // product name
  const [name, setName] = useState(get(product, 'product_name') || '')
  const [nameError, setNameError] = useState('')
  // trial
  const [hasTrial, setHasTrial] = useState(get(product, 'allow_trial') || false)
  // floating
  const [floatingTimeout, setFloatingTimeout] = useState(get(product, 'floating_timeout') || 120)
  const [floatingTimeoutError, setFloatingTimeoutError] = useState('')
  // floating policy
  const [isPolicySelectorDirty, setPolicySelectorDirty] = useState(false)
  const [selectedPolicy, setSelectedPolicy] = useState(defaultPolicy)
  // metadata
  const [metadata, setMetadata] = useState(parseMetadata(get(product, 'metadata')))
  const [metadataError, setMetadataError] = useState('')

  const getFormData = () => {
    const data = {
      product_name: name,
      // trial
      allow_trial: hasTrial,
      // floating timeout
      floating_timeout: Number(floatingTimeout),
      metadata: metadata ? JSON.parse(metadata) : {}
    }

    return data
  }

  // validation methods
  const validateValue = async (val, cb, includeZero = false) => {
    let errors
    try {
      errors = await validateRequiredNumber(val, includeZero)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit product form value', {
        value: val
      })
    }
    if (errors) {
      return false
    }
    return true
  }

  const validateMetadata = async val => {
    setLoading(true)
    let errors
    try {
      errors = await validateJSON(metadata)
      setMetadataError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit product form value', {
        value: val
      })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const handleProductNameValidation = async () => {
    let errors
    try {
      errors = await validateProductName(name, products, archivedProducts, currentProductName)
      setNameError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit product form name', {
        value: name
      })
    }
    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isNameValid = await handleProductNameValidation()
    const isFloatingTimeoutValid = await validateValue(floatingTimeout, setFloatingTimeoutError)
    const isMetadataValid = await validateMetadata(metadata, setMetadataError)

    return isNameValid && isFloatingTimeoutValid && isMetadataValid
  }

  const handleAsDefault = async policy => {
    const data = { ...policy, is_default: true }
    const policyID = get(policy, 'id')
    try {
      await patchLicensePolicy(policyID, companyID, data)
      Notification('success', __('Changes saved successfully'))
      refetchProduct()
      dispatch(getProducts(companyID))
      closeForm()
    } catch (err) {
      sendErrorReport(err, 'Cannot edit license policy defaults', data)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const isValid = await isFormValid()
    if (!isValid || isLoading) {
      return false
    }
    if (!dirty) {
      closeForm()
      return false
    }

    setLoading(true)
    const data = getFormData()
    const productID = get(product, 'id')

    patchProduct(productID, data, companyID)
      .then(() => {
        if (isPolicySelectorDirty) {
          handleAsDefault(selectedPolicy)
        } else {
          Notification('success', __('Changes saved successfully'))
          refetchProduct()
          dispatch(getProducts(companyID))
          closeForm()
        }
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit product', data)
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
    return true
  }

  const handlePolicySelect = val => {
    setPolicySelectorDirty(true)
    const productPolicies = get(product, 'license_templates') || []
    const selectedPol = productPolicies.find(pp => pp.code === val)
    setSelectedPolicy(selectedPol)
  }

  const handleClose = () => {
    if (!dirty) {
      closeForm()
    }
    setDirtyFormAlertDisplay(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      size='sm'
      title={get(product, 'product_name') || ''}
    >
      <form className='EditProductForm' onSubmit={handleSubmit}>
        <div className='second-tab'>
          <div className='left'>
            <div className='form-row'>
              <Label text={__('Product Name')} inputId='product-name' />
              <TextInput
                placeholder={__('Enter product name')}
                id='product-name'
                value={name}
                error={nameError}
                handleChange={val => {
                  setDirty(true)
                  setName(val)
                  debouncedValidateProductName(
                    val,
                    products,
                    archivedProducts,
                    currentProductName
                  ).then(err => setNameError(err))
                }}
              />
            </div>
            <div className='form-row'>
              {checkCompanyConfigField(companyID, 'isTrial') && (
                <Checkbox
                  label={__('Product has trial period')}
                  inputId='product-has-trial'
                  checked={hasTrial}
                  handleChange={val => {
                    setDirty(true)
                    setHasTrial(val)
                  }}
                />
              )}
            </div>
            {isEnterprise && (
              <div className='form-row'>
                <Label
                  text={__('Floating timeout')}
                  description={__(
                    'If the license issued is a floating license, the floating timeout is the time interval in minutes that the end users application will need to perform a license check in order to remain registered to the license.'
                  )}
                  inputId='floating-timeout-input'
                />
                <NumberInput
                  min='1'
                  max='2147483647'
                  id='floating-timeout-input'
                  value={floatingTimeout}
                  error={floatingTimeoutError}
                  handleChange={val => {
                    setDirty(true)
                    setFloatingTimeout(val)
                    debouncedValidateRequiredNumber(val).then(err => setFloatingTimeoutError(err))
                  }}
                />
              </div>
            )}
            <div className='form-row'>
              <Label inputId='product-policy' text={__('Default License Policy')} />
              <Selector
                options={formatPolicies(get(product, 'license_templates'))}
                handleChange={handlePolicySelect}
                value={get(selectedPolicy, 'code')}
                inputId='product-policy'
              />
            </div>
            <div className='form-row'>
              <Label inputId='metadata-input' text={__('Metadata JSON')} />
              <TextArea
                handleChange={val => {
                  setDirty(true)
                  setMetadata(val)
                  debouncedValidateJSON(val).then(err => setMetadataError(err))
                }}
                id='metadata-input'
                type='metadata'
                value={metadata}
                error={metadataError}
                rows='4'
                // todo_metadata replace this check with company feature
                disabled={!isEnterprise}
              />
            </div>
          </div>
        </div>
      </form>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeForm}
        />
      )}
    </Modal>
  )
}

EditProductForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  companyPlan: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  refetchProduct: PropTypes.func.isRequired
}

export default EditProductForm
