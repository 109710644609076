import React from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Notice,
  Notification,
  PermissionMissingNotificationTitle,
  Page,
  Subtitle
} from 'shared/components'
import { checkTrial, checkUserPermission } from 'shared/helpers'
import { userPermissions } from 'shared/constants'
import FreePlanTypeBox from './components/FreePlanTypeBox'
import StandardPlanTypeBox from './components/StandardPlanTypeBox'
import EnterprisePlanTypeBox from './components/EnterprisePlanTypeBox'
import './styles.scss'

const PlanTypeContainer = () => {
  const canManageBilling = checkUserPermission(userPermissions.billing_write)
  const history = useHistory()
  const planType = useSelector(state => get(state, 'company.details.plan_type'))
  const companyId = useSelector(state => get(state, 'company.details.id'))

  const routeToUpgradePage = () => {
    history.push(`/${companyId}/account/plan-type/upgrade`)
  }

  const trial = checkTrial()
  const formatPlanTypeString = () => {
    if (!planType) {
      return ''
    }
    return `${planType.charAt(0).toUpperCase()}${planType.slice(1)}`
  }

  const handleManageBillingClick = cb => {
    if (!canManageBilling) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.billing_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  return (
    <Page title={`${__('Plan type')}: ${formatPlanTypeString()}`}>
      <div className='PlanTypeContainer'>
        <div className='PlanTypeContainer-heading'>
          <Subtitle text={__('Edit your plan type')} />
          <div className='description'>
            {__('To compare different plan types in more detail click')}
            <a
              style={{ marginLeft: '3px' }}
              href='https://licensespring.com/pricing'
              target='_blank'
              rel='noopener noreferrer'
            >
              {__('here')}
            </a>
          </div>
        </div>
        {get(trial, 'isTrial') && (
          <div className='PlanTypeContainer-notice'>
            <Notice size='sm' theme='warning'>
              <>
                {__('Remaining trial days')}: &nbsp;
                {get(trial, 'remaining_days')}
              </>
            </Notice>
          </div>
        )}
        <div className='PlanTypeContainer-form'>
          <div className='form-row'>
            {planType === 'free' && (
              <FreePlanTypeBox isCurrent={planType === 'free'} handleCtaClick={() => {}} />
            )}
            {planType !== 'enterprise' && (
              <StandardPlanTypeBox
                isCurrent={planType === 'standard'}
                handleCtaClick={() => handleManageBillingClick(routeToUpgradePage)}
              />
            )}
            <EnterprisePlanTypeBox isCurrent={planType === 'enterprise'} />
          </div>
        </div>
      </div>
    </Page>
  )
}

export default PlanTypeContainer
