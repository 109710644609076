import React from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Select, { components } from 'react-select'
import { clearCompanyState } from 'src/company/actions'
import { IconDropdown } from 'shared/components'
import './styles.scss'

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <IconDropdown stroke='#fff' strokeWidth='2' height='20px' width='20px' />
  </components.DropdownIndicator>
)

const CompanySelector = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const activeCompanyId = useSelector(state => get(state, 'account.activeCompany.id'))
  const companies = useSelector(state => get(state, 'account.companies'))
  const companyLoading = useSelector(state => get(state, 'company.loading'))
  const loadingUserPermissions = useSelector(state => get(state, 'company.loadingUserPermissions'))

  const isSelectorDisabled = Array.isArray(companies) && companies.length === 1
  const customStyles = {
    option: provided => ({
      ...provided,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      outline: 'none'
    }),
    control: () => ({ width: 250, outline: 'none' }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.8 : 1,
      transition: 'opacity 300ms',
      cursor: 'pointer',
      outline: 'none'
    })
  }
  const companyToShow = activeCompanyId ? companies.find(c => c.id === activeCompanyId) : null

  return (
    <div className='CompanySelector'>
      <Select
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        options={companies}
        value={companyToShow}
        className='company-selector'
        classNamePrefix='company-selector-inner'
        isDisabled={isSelectorDisabled || companyLoading || loadingUserPermissions}
        styles={customStyles}
        onChange={company => {
          dispatch(clearCompanyState())
          history.push(`/${get(company, 'id')}`)
        }}
        components={{ DropdownIndicator }}
        placeholder={__('Select company')}
      />
    </div>
  )
}

export default CompanySelector
