import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  IconCheckmark,
  IconDisabled,
  IconDelete,
  IconEdit,
  JsonView,
  List,
  PermissionMissingNotificationTitle,
  SelectedFeaturesTags,
  SelectedCustomFieldsTags,
  Switcher,
  Notification,
  Notice
} from 'shared/components'
import {
  capitalizeFirstLetter,
  displayValue,
  formatDate,
  isFeatureEnabled,
  isFeatureAvailable,
  getDisabledMessage,
  sendErrorReport,
  displayMaxOveragesValue,
  getLicensePolicyFile,
  checkUserPermission
} from 'shared/helpers'
import {
  defaultDateFormat,
  licenseTypes,
  platformFeatures,
  userPermissions
} from 'shared/constants'
import {
  listLicensePolicies,
  patchLicensePolicy,
  deleteLicensePolicy,
  getProducts,
  getDefaultLicensePolicy
} from 'src/product/actions'
import { LicensePolicyForm } from 'src/product/Forms'

const LicensePolicies = ({ companyID, product, refetchProduct }) => {
  const canManageProducts = checkUserPermission(userPermissions.products_write)
  const dispatch = useDispatch()
  const isUserBased = get(product, 'authorization_method') === 'user'
  const productID = get(product, 'id')
  const [isFormDisplayed, setFormDisplay] = useState(false)
  const [policyToEdit, setPolicyToEdit] = useState(null)
  const [policiesLoading, setPoliciesLoading] = useState(true)
  const [policies, setPolicies] = useState([])
  const [switcherLoading, setSwitcherLoading] = useState(false)
  const [policyToDelete, setPolicyToDelete] = useState(null)
  const [isPolicyDeleteConfirmationDisplayed, setPolicyDeleteConfirmationDisplayed] = useState(
    false
  )
  const [policyDeleteLoading, setPolicyDeleteLoading] = useState(false)
  const [tableExpanded, setTableExpanded] = useState({})
  const [page, setPage] = useState(0)
  const [policiesCount, setPoliciesCount] = useState(0)
  const [defaultPolicy, setDefaultPolicy] = useState(null)
  const pageSize = 20

  const getDefaultPolicy = () => {
    getDefaultLicensePolicy(companyID, productID)
      .then(res => {
        const data = get(res, 'data.results') || []
        setDefaultPolicy(data)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot fetch default license policy')
      })
  }

  const getPolicies = useCallback(() => {
    setPoliciesLoading(true)
    listLicensePolicies(companyID, productID, page, pageSize)
      .then(res => {
        const data = get(res, 'data.results') || []
        setPolicies(data)
        setPoliciesCount(get(res, 'data.count'))
        refetchProduct()
        setPoliciesLoading(false)
        setSwitcherLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot fetch license policies')
        setPoliciesLoading(false)
        setSwitcherLoading(false)
      })
  }, [productID, page])

  const handlePageChange = newPage => {
    setPage(newPage)
  }

  const handleDefaultChange = async (policy, isDefault) => {
    // chck if this is the only one set as default. If yes, do not allow
    const countDefaultPolicies = policies.reduce((count, p) => count + p.is_default, 0)
    if (countDefaultPolicies === 1 && isDefault) {
      Notification(
        'error',
        __('Your changes were not saved'),
        __('Product needs to have at least one default policy defined.')
      )
      return false
    }
    setSwitcherLoading(true)
    const data = { is_default: !isDefault }
    const policyID = get(policy, 'id')
    try {
      await patchLicensePolicy(policyID, companyID, data)
      Notification('success', __('Changes saved successfully'), __('License policy updated'))
      getPolicies()
      refetchProduct()
      dispatch(getProducts(companyID))
    } catch (err) {
      sendErrorReport(err, 'Cannot edit license policy defaults', data)
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      )
      setSwitcherLoading(false)
    }
    return true
  }

  const handlePolicyDelete = async () => {
    const policyID = get(policyToDelete, 'id')
    setPolicyDeleteLoading(true)
    try {
      await deleteLicensePolicy(policyID, companyID)
      Notification('success', __('Changes saved successfully'), __('License policy deleted'))
      getPolicies()
      refetchProduct()
      dispatch(getProducts(companyID))
      setPolicyDeleteLoading(false)
      setPolicyDeleteConfirmationDisplayed(false)
      setPolicyToDelete(null)
    } catch (err) {
      sendErrorReport(err, 'Cannot delete license policy')
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      )
      setPolicyDeleteLoading(false)
    }
  }

  useEffect(() => {
    getPolicies()
    getDefaultPolicy()
  }, [getPolicies, page])

  const handleManagePolicyClick = cb => {
    if (!canManageProducts) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.products_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  const allowsAirGapped =
    isFeatureEnabled(platformFeatures.extra_air_gapped) &&
    isFeatureAvailable(platformFeatures.extra_air_gapped)

  return (
    <div className='LicensePolicies'>
      <div className='list-header'>
        <div>
          <Button onClick={() => handleManagePolicyClick(setFormDisplay)} size='sm' theme='info'>
            {__('Add license policy')}
          </Button>
        </div>
      </div>
      {!defaultPolicy && (
        <Notice size='sm' title={__('Set a default license policy.')} theme='error'>
          <div>{__('Product needs to have at least one default policy defined.')}</div>
        </Notice>
      )}
      <List
        data={policies}
        minRows={2}
        page={page}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        pages={Math.ceil(policiesCount / pageSize)}
        showPagination
        manual
        loading={policiesLoading}
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none'
            }
          },
          {
            accessor: 'name',
            Header: __('Name')
          },
          {
            accessor: 'code',
            Header: __('Code')
          },
          {
            accessor: 'default_license_type',
            Header: __('License Type')
          },
          {
            accessor: 'created_at',
            Header: __('Created on'),
            Cell: cellData => formatDate(cellData.value, defaultDateFormat)
          },
          {
            accessor: 'allow_trial',
            Header: __('Is trial'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData =>
              cellData.value ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='#aaa' height='14px' />
              ),
            width: 100
          },
          {
            accessor: 'is_air_gapped',
            Header: __('Is air-gapped'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData =>
              cellData.value ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='#aaa' height='14px' />
              ),
            width: 120,
            show: allowsAirGapped
          },
          {
            accessor: 'is_default',
            Header: __('Is default'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: rowData => (
              <Switcher
                checked={rowData.value}
                handleChange={() =>
                  handleManagePolicyClick(() =>
                    handleDefaultChange(rowData.original, rowData.value)
                  )
                }
                size='sm'
                disabled={switcherLoading || policies.length < 2}
              />
            ),
            maxWidth: 120
          },
          {
            className: 'text-center',
            id: 'edit',
            width: 80,
            sortable: false,
            Cell: rowData => (
              <Button
                className='edit-button'
                onClick={() =>
                  handleManagePolicyClick(() => {
                    const isAirGapPolicy = get(rowData, 'original.is_air_gapped')
                    if (isAirGapPolicy) {
                      Notification('error', __('Air-gapped license policy cannot be edited'))
                      return false
                    }
                    setPolicyToEdit(rowData.original)
                    setFormDisplay(true)
                    return true
                  })
                }
                type='button'
              >
                <IconEdit height='16px' width='16px' />
              </Button>
            )
          },
          {
            className: 'text-center',
            id: 'delete',
            width: 80,
            sortable: false,
            Cell: rowData => (
              <Button
                className='edit-button'
                onClick={() =>
                  handleManagePolicyClick(() => {
                    const isDefault = get(rowData, 'original.is_default')
                    const isAirGapPolicy = get(rowData, 'original.is_air_gapped')
                    if (isDefault) {
                      Notification('error', __('Default license policy cannot be deleted'))
                      return false
                    }
                    if (isAirGapPolicy) {
                      Notification('error', __('Air-gapped license policy cannot be deleted'))
                      return false
                    }
                    setPolicyToDelete(rowData.original)
                    setPolicyDeleteConfirmationDisplayed(true)
                    return true
                  })
                }
                type='button'
              >
                <IconDelete height='16px' width='16px' color='#ee5253' />
              </Button>
            ),
            maxWidth: 50
          }
        ]}
        SubComponent={row => {
          const isTrial = get(row, 'original.allow_trial')
          const isAirGapped = get(row, 'original.is_air_gapped')
          const isConsumption =
            get(row, 'original.default_license_type') === licenseTypes.consumption
          const isTimeLimited =
            get(row, 'original.default_license_type') === licenseTypes.time_limited
          const isSubscription =
            get(row, 'original.default_license_type') === licenseTypes.subscription
          const hasExpirationDate = get(row, 'original.validity_period')
          const allowGracePeriod = get(row, 'original.allow_grace_period')
          const gracePeriod = get(row, 'original.grace_period')
          const hasSelectedProductFeatures = get(
            row,
            'original.license_product_feature_templates.length'
          )
          const hasSelectedCustomFields = get(row, 'original.license_custom_field_templates.length')

          return (
            <div className='SubComponent'>
              <DescriptionTable
                details={[
                  {
                    label: __('Max activations'),
                    value: get(row, 'original.allow_unlimited_activations')
                      ? __('Unlimited')
                      : displayValue(get(row, 'original.max_activations'))
                  },
                  {
                    label: isUserBased ? __('Max license users') : null,
                    value:
                      get(row, 'original.unlimited_max_license_users') ||
                      get(row, 'original.max_license_users') === 0
                        ? __('Unlimited')
                        : displayValue(get(row, 'original.max_license_users'))
                  },
                  {
                    label:
                      isTrial && !get(row, 'original.validity_period') ? __('Trial days') : null,
                    value: get(row, 'original.trial_days')
                  },
                  {
                    label: isTimeLimited || isSubscription ? __('Valid duration') : null,
                    value: displayValue(get(row, 'original.valid_duration'))
                  },
                  {
                    label: isSubscription ? __('Valid duration') : null,
                    value: displayValue(get(row, 'original.valid_duration'))
                  },
                  {
                    label: hasExpirationDate ? __('Expiration date') : null,
                    value: formatDate(get(row, 'original.validity_period'), defaultDateFormat)
                  },
                  {
                    label: isSubscription && allowGracePeriod ? __('Grace period') : null,
                    value: `${gracePeriod} ${__('hours')}`
                  },
                  // show consumption values
                  {
                    label: isConsumption ? __('Max consumptions') : null,
                    value: get(row, 'original.allow_unlimited_consumptions')
                      ? __('Unlimited')
                      : displayValue(get(row, 'original.max_consumptions'))
                  },
                  {
                    label: isConsumption ? __('Allow negative consumptions') : null,
                    value: get(row, 'original.allow_negative_consumptions') ? __('Yes') : __('No')
                  },
                  {
                    label: isConsumption ? __('Allow overages') : null,
                    value: get(row, 'original.allow_overages') ? __('Yes') : __('No')
                  },
                  {
                    label: isConsumption ? __('Max overages') : null,
                    value: displayMaxOveragesValue(get(row, 'original'))
                  },
                  {
                    label: isConsumption ? __('Reset consumption') : null,
                    value: get(row, 'original.reset_consumption') ? __('Yes') : __('No')
                  },
                  {
                    label: isConsumption ? __('Consumption period') : null,
                    value: displayValue(
                      capitalizeFirstLetter(get(row, 'original.consumption_period'))
                    )
                  },
                  // show everything else
                  {
                    label: get(row, 'original.enable_maintenance_period')
                      ? __('Maintenance duration')
                      : null,
                    value: displayValue(get(row, 'original.maintenance_duration'))
                  },
                  {
                    label: get(row, 'original.is_floating') ? __('Offline floating license') : null,
                    value: get(row, 'original.is_floating') ? __('Yes') : __('No')
                  },
                  {
                    label: get(row, 'original.is_floating_cloud') ? __('Is floating cloud') : null,
                    value: get(row, 'original.is_floating_cloud') ? __('Yes') : __('No')
                  },
                  {
                    label:
                      get(row, 'original.is_floating') || get(row, 'original.is_floating_cloud')
                        ? __('Max simultaneous license users')
                        : null,
                    value: displayValue(get(row, 'original.floating_users'))
                  },
                  {
                    label:
                      get(row, 'original.is_floating') || get(row, 'original.is_floating_cloud')
                        ? __('Floating timeout')
                        : null,
                    value: `${displayValue(get(row, 'original.floating_timeout'))} min`
                  },
                  {
                    label: get(row, 'original.can_borrow') ? __('Can borrow') : null,
                    value: get(row, 'original.can_borrow') ? __('Yes') : __('No')
                  },
                  {
                    label: get(row, 'original.can_borrow') ? __('Max borrow time') : null,
                    value: `${displayValue(get(row, 'original.max_borrow_time'))} ${__('hours')}`
                  },
                  {
                    label: get(row, 'original.max_transfers') ? __('Device transfer limit') : null,
                    value:
                      get(row, 'original.max_transfers') === -1
                        ? __('Device transfer not allowed')
                        : displayValue(get(row, 'original.max_transfers'))
                  },
                  {
                    label: __('Prevent virtual machine'),
                    value: get(row, 'original.prevent_vm') ? __('Yes') : __('No')
                  },
                  {
                    label: __('Require hardware key'),
                    value: get(row, 'original.is_hardware_key_auth') ? __('Yes') : __('No')
                  },
                  {
                    label: hasSelectedProductFeatures ? __('Product features') : null,
                    value: (
                      <SelectedFeaturesTags
                        features={get(row, 'original.license_product_feature_templates')}
                      />
                    )
                  },
                  {
                    label: hasSelectedCustomFields ? __('Custom fields') : null,
                    value: (
                      <SelectedCustomFieldsTags
                        cFields={get(row, 'original.license_custom_field_templates')}
                      />
                    )
                  },
                  {
                    label: __('Metadata'),
                    value: <JsonView value={get(row, 'original.metadata')} name='metadata' />
                  }
                ]}
              />
              {isAirGapped && (
                <div className='SubComponent-actions'>
                  <Button
                    featureEnabled={isFeatureEnabled(platformFeatures.extra_air_gapped)}
                    featureAvailable={isFeatureAvailable(platformFeatures.extra_air_gapped)}
                    notEnabledMessage={getDisabledMessage()}
                    size='sm'
                    // loading={licenseRefreshLoading === get(row, 'original.hardware_id')}
                    onClick={() => getLicensePolicyFile(get(row, 'original'))}
                  >
                    {__('Download license policy file')}
                  </Button>
                </div>
              )}
            </div>
          )
        }}
      />
      {isFormDisplayed && (
        <LicensePolicyForm
          closeCb={() => {
            setFormDisplay(false)
            setPolicyToEdit(null)
          }}
          policy={policyToEdit}
          policies={policies}
          product={product}
          companyID={companyID}
          refetchPolicies={getPolicies}
        />
      )}
      {isPolicyDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setPolicyToDelete(null)
            setPolicyDeleteConfirmationDisplayed(false)
          }}
          confirmCb={handlePolicyDelete}
          title={`${__('Are you sure you want to delete this license policy')}?`}
          confirmText={__('Delete')}
          theme='error'
          disabled={policyDeleteLoading}
        >
          <span style={{ fontSize: '14px', wordBreak: 'break-all' }}>
            {get(policyToDelete, 'name')}
          </span>
        </ConfirmationPopup>
      )}
    </div>
  )
}

LicensePolicies.propTypes = {
  companyID: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  refetchProduct: PropTypes.func.isRequired
}

export default LicensePolicies
